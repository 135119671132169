import { Divider, Stack } from "@mui/joy"
import { Grid2 as Grid } from "@mui/material"
import React from "react"
import { ApplicationCreate } from "@/components/application-create/application-create"
import { OverviewApplications } from "@/components/overview-applications/overview-applications"
import { OverviewEntity } from "@/components/overview-entity/overview-entity"
import { OverviewGameServerBuild } from "@/components/overview-game-server-build/overview-game-server-build"
import { OverviewTree } from "@/components/overview-tree/overview-tree"
import { SpaceCreate } from "@/components/space-create/space-create"
import { SpaceValidator } from "@/components/space-validator/space-validator"
import { Spinner } from "@/components/spinner/spinner"
import { useAppSelector } from "@/hooks/store"
import { usePageActions } from "@/hooks/usePageActions"
import { useGetAllApplicationsByGameIdQuery } from "@/store/api/application"
import { useFindAllConfigsQuery } from "@/store/api/config"
import { useGetAllGamesQuery } from "@/store/api/game"

import { useFindAllSpacesQuery } from "@/store/api/space"
import { selectEnv, selectGame, selectSpace } from "@/store/slices/context"

export const OverviewPage: React.FC = () => {
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const spaceId = useAppSelector(selectSpace)
  const spaces = useFindAllSpacesQuery({ env }, { skip: !env })
  const games = useGetAllGamesQuery({ env }, { skip: !env })
  const config = useFindAllConfigsQuery({ spaceId }, { skip: !spaceId })
  const apps = useGetAllApplicationsByGameIdQuery(
    { env, gameId },
    { skip: !env || !gameId }
  )

  const isFetching = spaceId
    ? spaces.isFetching ||
      games.isFetching ||
      apps.isFetching ||
      config.isLoading
    : spaces.isFetching || games.isFetching || apps.isFetching

  const currentData = spaceId
    ? spaces.currentData ||
      games.currentData ||
      apps.currentData ||
      config.currentData
    : spaces.currentData || games.currentData || apps.currentData

  usePageActions([<SpaceCreate />, <ApplicationCreate />])

  return (
    <Grid container spacing={3} sx={{ flexGrow: 1 }}>
      <Grid size={{ xs: 12, sm: 12, lg: 3, xl: 2 }}>
        <OverviewTree />
      </Grid>
      <Grid size={{ xs: 12, sm: 12, lg: 9, xl: 10 }} position="relative">
        <Spinner loading={isFetching} />

        {currentData && (
          <Stack sx={{ gap: 3 }} divider={<Divider inset="context" />}>
            <Grid container spacing={3}>
              <Grid size={{ xs: 12, lg: 6 }}>
                <OverviewEntity />
              </Grid>
              <Grid size={{ xs: 12, lg: 6 }}>
                <SpaceValidator />
              </Grid>
            </Grid>

            <Grid container spacing={3}>
              <Grid size={{ xs: 12, lg: 6 }}>
                <OverviewApplications />
              </Grid>

              <Grid size={{ xs: 12, lg: 6 }}>
                <OverviewGameServerBuild />
              </Grid>
            </Grid>
          </Stack>
        )}
      </Grid>
    </Grid>
  )
}
