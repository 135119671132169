import { MouseEventHandler } from "react"

export enum DataState {
  WAITING_FOR_UPLOAD = "state.waitingForUpload",
  READY_FOR_USE = "state.readyForUse",
  IN_USE = "state.inUse"
}

export const Actions: Record<string, string> = {
  update: "update",
  create: "create",
  delete: "delete",
  deploy: "deploy",
  publish: "publish",
  reset: "reset",
  set: "set"
} as const

export type Action = (typeof Actions)[keyof typeof Actions]

export type ActionsMenu = Record<
  Action,
  { handler: MouseEventHandler; label?: string; disabled?: boolean }
>
