import { Notification, Severity } from "@liveops-portal/lib"
import {
  Box,
  Button,
  Card,
  CardContent,
  CardOverflow,
  ColorPaletteProp,
  Divider,
  IconButton,
  Stack,
  SvgIcon,
  Typography
} from "@mui/joy"
import {
  CheckCircleSolid,
  InfoCircleSolid,
  WarningTriangleSolid,
  Xmark,
  XmarkCircleSolid
} from "iconoir-react"
import { ToastContent, ToastContentProps } from "react-toastify"
import i18next from "@/i18n"

export const NotificationItem: ToastContent<Notification> = ({
  closeToast,
  data: { message, severity = Severity.Info, actions, error }
}: ToastContentProps<Notification>) => {
  let icon = InfoCircleSolid
  let color: ColorPaletteProp = "neutral"

  switch (severity) {
    case Severity.Success:
      icon = CheckCircleSolid
      color = "success"
      break
    case Severity.Error:
      icon = XmarkCircleSolid
      color = "danger"
      break
    case Severity.Warning:
      icon = WarningTriangleSolid
      color = "warning"
      break
  }

  const hasErrorMessage =
    (error && typeof error === "string" && !!(error as string).length) ||
    (error &&
      typeof error !== "string" &&
      "message" in error &&
      !!error.message?.length)

  const title =
    error && typeof error !== "string" && "error" in error
      ? error.error
      : i18next.t(`severity.${severity}`)

  return (
    <Card
      size="sm"
      role="alert"
      color={color}
      variant="soft"
      invertedColors
      sx={{ flexGrow: 1 }}
    >
      <CardContent sx={{ gap: 1 }}>
        <Stack sx={{ flexDirection: "row", alignItems: "flex-start" }}>
          <SvgIcon size="md" component={icon} sx={{ mr: 1 }} />

          <Box sx={{ flexGrow: 1 }}>
            <Typography level="title-sm">{title}</Typography>
            <Typography level="body-xs" color={color}>
              {message}
            </Typography>
          </Box>

          <IconButton
            variant="plain"
            onClick={closeToast}
            sx={{ "--IconButton-size": "32px" }}
          >
            <Xmark />
          </IconButton>
        </Stack>
        {!!actions?.length && (
          <Stack sx={{ gap: 1, flexDirection: "row" }}>
            {actions.map(({ label, handler }) => (
              <Button
                key={label}
                onClick={handler}
                color={color}
                variant="soft"
                sx={{ flexGrow: 1 }}
              >
                {label}
              </Button>
            ))}
          </Stack>
        )}
      </CardContent>
      {hasErrorMessage && (
        <CardOverflow variant="soft">
          <Divider inset="context" />
          <CardContent>
            <Typography level="body-xs" textColor="text.tertiary">
              {typeof error === "string" ? error : error.message}
            </Typography>
          </CardContent>
        </CardOverflow>
      )}
    </Card>
  )
}
