import { Action, Actions } from "@liveops-portal/lib"
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemDecorator,
  Stack,
  Typography
} from "@mui/joy"
import { Minus, Plus } from "iconoir-react"
import { useMemo } from "react"
import {
  ArrayPath,
  Control,
  FieldArray,
  FieldValues,
  SubmitHandler,
  useFieldArray,
  UseFormReturn
} from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "@/components/form/form"
import { FormInput } from "@/components/form-input/form-input"
import { FormSelect } from "@/components/form-select/form-select"
import { FormSwitch } from "@/components/form-switch/form-switch"
import { Modal } from "@/components/modal/modal"
import { useAppSelector } from "@/hooks/store"
import { useGetGameDataBySeasonIdQuery } from "@/store/api/game-data"
import { selectEnv, selectGame, selectSeason } from "@/store/slices/context"

interface Props<T extends FieldValues> {
  open: boolean
  loading: boolean
  action: Action
  methods: UseFormReturn<T>
  onSubmit: SubmitHandler<T>
  onClose: () => void
}

export const ChallengeModal = <T extends FieldValues>({
  open,
  loading,
  action,
  methods,
  onClose,
  onSubmit
}: Props<T>) => {
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const seasonId = useAppSelector(selectSeason)
  const { t } = useTranslation()
  const { fields, append, remove } = useFieldArray({
    control: methods.control as Control<T>,
    name: "levels" as ArrayPath<T>
  })
  const { data: filters } = useGetGameDataBySeasonIdQuery(
    { gameId, seasonId, env, dataTypes: ["challenge-filters"] },
    { skip: !seasonId || !gameId || !env }
  )

  const filter = useMemo(
    () =>
      filters?.["challenge-filters"]?.map((challengeFilter) => ({
        value: challengeFilter.guid as string,
        label: challengeFilter.name as string
      })),
    [filters]
  )

  const progressType = useMemo(
    () => [
      {
        value: "highest",
        label: t("label.season.progressTypes.highest")
      },
      {
        value: "accumulation",
        label: t("label.season.progressTypes.accumulation")
      }
    ],
    [t]
  )

  const scope = useMemo(
    () => [
      {
        value: "season",
        label: t("item.season")
      }
    ],
    [t]
  )

  const item = "item.challenge"

  return (
    <Modal
      open={open}
      onClose={onClose}
      title={t("action.item", {
        action: `action.${action}`,
        item
      })}
    >
      <Form
        auditable={action === Actions.update}
        methods={methods}
        loading={loading}
        submitLabel={t(`action.${action}`)}
        onSubmit={onSubmit}
        gap={2}
        direction="column"
      >
        <Stack direction="row" gap={2}>
          <Stack gap={2} justifyContent="flex-start">
            <Stack direction="row" gap={1}>
              <FormInput
                type="text"
                name="displayName"
                label={t("modifier.name", { item })}
                rules={{
                  required: true
                }}
              />
              <FormSelect
                name="scope"
                label={t("label.season.scope")}
                options={scope}
                rules={{
                  required: true
                }}
                sx={{ minWidth: 130 }}
              />
            </Stack>

            <FormSelect
              name="filter"
              label={t("label.filter")}
              options={filter || []}
              rules={{
                required: true
              }}
            />
            <FormSelect
              name="progressType"
              label={t("label.season.progressType")}
              options={progressType}
              rules={{
                required: true
              }}
            />
            <FormSwitch
              orientation="horizontal"
              name="progressInMenu"
              label={t("label.season.progressInMenu")}
            />
          </Stack>

          <Divider orientation="vertical" />

          <Stack gap={1} minWidth={400}>
            <Typography
              fontSize="sm"
              fontWeight="md"
              mt={1}
              ml={3}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {t("item.levels")}
              <IconButton
                size="sm"
                variant="outlined"
                title={t("action.item", {
                  action: "action.add",
                  item: "item.level"
                })}
                aria-label={t("action.item", {
                  action: "action.add",
                  item: "item.level"
                })}
                onClick={() => {
                  append({
                    threshold: null,
                    reward: ""
                  } as FieldArray<T, ArrayPath<T>>)
                }}
                sx={{ ml: 1 }}
              >
                <Plus />
              </IconButton>
            </Typography>
            <List
              sx={({ spacing }) => ({
                maxHeight: 280,
                overflow: "auto",
                "--List-padding": 0,
                "--List-gap": spacing(2),
                "--ListItem-paddingY": 0,
                "--ListItem-paddingX": 0,
                "--ListItemDecorator-size": spacing(3)
              })}
            >
              {fields.map((field, index) => (
                <ListItem key={field.id}>
                  <Stack direction="row" gap={1} flexGrow={1}>
                    <ListItemDecorator
                      component={Typography}
                      alignSelf="flex-end"
                      mb={0.5}
                    >
                      {index + 1}
                    </ListItemDecorator>
                    <FormInput
                      type="number"
                      name={`levels.${index}.threshold`}
                      label={t("label.season.threshold")}
                      rules={{
                        required: true,
                        min: 1
                      }}
                      slotProps={{
                        input: { min: 1 }
                      }}
                      sx={{ maxWidth: 80 }}
                    />

                    <FormInput
                      type="text"
                      name={`levels.${index}.reward`}
                      label={t("item.reward")}
                      rules={{
                        required: true
                      }}
                      sx={{ flexGrow: 1 }}
                    />
                    {index > 0 && (
                      <IconButton
                        size="sm"
                        variant="outlined"
                        title={t("action.item", {
                          action: "action.remove",
                          item: "item.level"
                        })}
                        aria-label={t("action.item", {
                          action: "action.remove",
                          item: "item.level"
                        })}
                        onClick={() => {
                          remove(index)
                        }}
                        sx={{ alignSelf: "flex-end" }}
                      >
                        <Minus />
                      </IconButton>
                    )}
                  </Stack>
                </ListItem>
              ))}
            </List>
          </Stack>
        </Stack>
      </Form>
    </Modal>
  )
}
