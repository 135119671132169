import { Operation, FeatureSwitch, insertIf } from "@liveops-portal/lib"
import { CircularProgress, Switch, Typography } from "@mui/joy"
import { Grid2 as Grid } from "@mui/material"
import React, { useEffect, useMemo, useState } from "react"
import { Trans, useTranslation } from "react-i18next"
import { CardItem } from "@/components/card-item/card-item"
import { FeatureSwitchUpdate } from "@/components/feature-switch-update/feature-switch-update"
import { OperationReason } from "@/components/operation-reason/operation-reason"
import { isOperationActive } from "@/helpers/operation-reason"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import {
  useResetSwitchMutation,
  useSetSwitchMutation
} from "@/store/api/feature-switch"
import {
  useFindAllGameDataVersionsQuery,
  useFindAllGameDataQuery
} from "@/store/api/game-data"
import {
  resetOperation,
  selectActiveOperation,
  setActiveOperation
} from "@/store/slices/audit"
import { selectEnv, selectGame, selectSpace } from "@/store/slices/context"

export const FeatureSwitchCard: React.FC<FeatureSwitch> = ({
  switchName: name,
  switchType: type,
  switchValue: currentValue,
  switchDescription: description,
  switchDataSource: dataType = null,
  defaultValue,
  overrideValue
}) => {
  const { t, i18n } = useTranslation()
  const dispatch = useAppDispatch()
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const spaceId = useAppSelector(selectSpace)

  const activeOperation = useAppSelector(selectActiveOperation)
  const setSwitchOperation: Operation = [name, "set"]
  const resetSwitchOperation: Operation = [name, "reset"]
  const isSetSwitchOperationActive = isOperationActive(
    activeOperation,
    setSwitchOperation
  )
  const isResetSwitchOperationActive = isOperationActive(
    activeOperation,
    resetSwitchOperation
  )

  const [switchValue, setSwitchValue] =
    useState<FeatureSwitch["overrideValue"]>(overrideValue)
  const [setSwitch, setMutation] = useSetSwitchMutation()
  const [resetSwitch, resetMutation] = useResetSwitchMutation()

  const { data: dataVersions } = useFindAllGameDataVersionsQuery(
    { env, gameId },
    { skip: !env || !gameId }
  )

  const { data: dataSource, isLoading: isLoadingDataSource } =
    useFindAllGameDataQuery(
      {
        env,
        gameId,
        dataVersion: dataVersions
          ? dataVersions[dataVersions.length - 1]
          : null,
        dataType
      },
      { skip: !env || !gameId || !dataType || !dataVersions }
    )

  const isLoadingSet = isSetSwitchOperationActive || setMutation.isLoading
  const isLoadingDelete =
    isResetSwitchOperationActive || resetMutation.isLoading
  const isLoadingActions = isLoadingSet || isLoadingDelete
  const isBool =
    typeof currentValue === "boolean" && typeof defaultValue === "boolean"
  const isReadOnly = useMemo(() => type === "list<int>", [type])
  const isOverridden = useMemo(() => overrideValue !== null, [overrideValue])

  const chips = useMemo(() => {
    let listState: { content: string; color: string }[] = []
    if (Array.isArray(currentValue) && dataSource) {
      listState = !currentValue?.length
        ? [
            {
              content: t("common.none"),
              color: "primary"
            }
          ]
        : dataSource.length === currentValue.length
          ? [
              {
                content: t("common.all"),
                color: "primary"
              }
            ]
          : dataSource
              ?.filter(({ guid }) =>
                (overrideValue as (string | number)[]).includes(guid)
              )
              .map((item) => ({
                content: item.data?.name || item.name,
                color: "primary"
              }))
    }

    return [
      ...insertIf(isBool, {
        content: currentValue ? "Enabled" : "Disabled",
        color: currentValue ? "success" : "danger"
      }),
      ...insertIf(isOverridden, {
        content: t("state.overridden"),
        color: "warning"
      }),
      ...insertIf(isReadOnly, {
        content: t("state.readOnly"),
        color: "warning"
      })
    ].concat(listState)
  }, [
    t,
    isBool,
    isOverridden,
    isReadOnly,
    dataSource,
    currentValue,
    overrideValue
  ])

  const onSubmitHandler = () => {
    if (isSetSwitchOperationActive) {
      setSwitch({
        spaceId,
        switchName: name,
        switchValue
      })
    }

    if (isResetSwitchOperationActive) {
      resetSwitch({
        spaceId,
        switchName: name
      })
    }
  }

  useEffect(() => {
    ;((isSetSwitchOperationActive && setMutation.isSuccess) ||
      (isResetSwitchOperationActive && resetMutation.isSuccess)) &&
      dispatch(resetOperation())
  }, [
    dispatch,
    resetMutation.isSuccess,
    setMutation.isSuccess,
    isResetSwitchOperationActive,
    isSetSwitchOperationActive
  ])

  const renderListSwitchDefault = () => (
    <>
      {Array.isArray(defaultValue) &&
      defaultValue.length &&
      defaultValue.length !== dataSource?.length ? (
        React.Children.toArray(
          defaultValue?.map((item, index) => (
            <>
              <Typography color="primary" fontWeight="xl">
                {item}
              </Typography>
              {index < defaultValue.length - 1 && ", "}
            </>
          ))
        )
      ) : (
        <Typography color="primary" fontWeight="xl">
          {dataSource &&
            Array.isArray(defaultValue) &&
            (!defaultValue?.length
              ? t("common.none")
              : defaultValue.length === dataSource.length && t("common.all"))}
        </Typography>
      )}
    </>
  )

  return (
    <Grid size={{ xs: 12, lg: 6 }} sx={{ display: "flex" }}>
      <OperationReason
        onSubmit={onSubmitHandler}
        open={isLoadingActions}
        autofocus
        closeable
      />
      <CardItem
        title={{ content: name }}
        active={isLoadingActions}
        color={isOverridden ? "warning" : "neutral"}
        sx={{
          ...(isOverridden && {
            "--Active-borderColor": ({ palette }) =>
              palette.warning.outlinedBorder,
            backgroundColor: "warning.softBg"
          })
        }}
        chips={chips}
        maxChips={6}
        subtitles={[
          {
            content: isBool ? (
              i18n.exists(`description.featureSwitch.${name}`) ? (
                <Trans
                  i18nKey={`description.featureSwitch.${name}`}
                  values={{
                    state: defaultValue ? "enabled" : "disabled"
                  }}
                  components={[
                    <Typography
                      color={defaultValue ? "success" : "danger"}
                      fontWeight="xl"
                    />
                  ]}
                />
              ) : (
                <>
                  {description}.{" "}
                  {defaultValue ? (
                    <Trans
                      i18nKey="state.enabledDefault"
                      components={[
                        <Typography color="success" fontWeight="xl" />
                      ]}
                    />
                  ) : (
                    <Trans
                      i18nKey="state.disabledDefault"
                      components={[
                        <Typography color="danger" fontWeight="xl" />
                      ]}
                    />
                  )}
                  .
                </>
              )
            ) : i18n.exists(`description.featureSwitch.${name}`) ? (
              <Trans
                i18nKey={`description.featureSwitch.${name}`}
                components={[renderListSwitchDefault()]}
              />
            ) : (
              <>
                {description}. {t("label.defaultSetting")}:{" "}
                {renderListSwitchDefault()}
              </>
            ),
            props: { fontStyle: "italic", fontWeight: "400" }
          }
        ]}
        sections={[
          ...insertIf(isBool, {
            content: (
              <Switch
                aria-label={t("action.override")}
                checked={!!currentValue}
                color={currentValue ? "primary" : "neutral"}
                onClick={(event) => {
                  const { checked } = event.target as HTMLInputElement
                  dispatch(setActiveOperation(setSwitchOperation))
                  setSwitchValue(checked)
                }}
                slotProps={{
                  track: {
                    children: isSetSwitchOperationActive && (
                      <CircularProgress
                        variant="plain"
                        data-testid="switch-pending"
                        sx={{
                          "--CircularProgress-size": "16px",
                          "--CircularProgress-progressThickness": "3px",
                          "--CircularProgress-progressColor": "white",
                          ml: currentValue ? "5px" : "30px",
                          transition: "margin 0.2s"
                        }}
                      />
                    )
                  }
                }}
              />
            )
          }),
          ...insertIf(!isBool, {
            content: (
              <FeatureSwitchUpdate
                name={name}
                disabled={isReadOnly || isLoadingDataSource}
                options={
                  dataSource?.map((item) => ({
                    label: item.data?.name || item.name,
                    id: item.guid
                  })) || []
                }
                preSelected={
                  overrideValue !== null
                    ? (overrideValue as (string | number)[])
                    : []
                }
              />
            ),
            props: { sx: { minWidth: 50 } }
          })
        ]}
        actions={{
          ...(isOverridden && {
            reset: {
              label: t("action.resetToDefault"),
              handler: () => {
                dispatch(setActiveOperation(resetSwitchOperation))
              }
            }
          })
        }}
      />
    </Grid>
  )
}
