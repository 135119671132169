import {
  GameServerBuildWarmUpRequest,
  GameServerRegions
} from "@liveops-portal/lib"
import { IconButton, Stack, Table } from "@mui/joy"
import { Minus, Plus } from "iconoir-react"
import { Dispatch, SetStateAction, useCallback, useEffect } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "@/components/form/form"
import { FormInput } from "@/components/form-input/form-input"
import { FormLabel } from "@/components/form-label/form-label"
import { FormSelect } from "@/components/form-select/form-select"
import { Modal } from "@/components/modal/modal"
import { useAppSelector } from "@/hooks/store"
import { useWarmUpGameServerMutation } from "@/store/api/game-server-build"
import { selectEnv, selectGame, selectSpace } from "@/store/slices/context"

type FormData = GameServerBuildWarmUpRequest

interface Props {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

export const GameServerBuildWarm = ({ open, setOpen }: Props) => {
  const { t } = useTranslation()
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const spaceId = useAppSelector(selectSpace)
  const methods = useForm<FormData>({
    mode: "all",
    defaultValues: {
      gameId,
      spaceId,
      envName: env,
      gameServerPerMachine: 2,
      hoursWarmup: 1,
      regions: [
        {
          regionName: "placeholder" as keyof typeof GameServerRegions,
          minIdleCount: 1,
          maxIdleCount: 1
        }
      ],
      qdOperationReason: "Game server warm up from LiveOps Portal"
    }
  })
  const { control, reset: resetForm, getValues, setValue } = methods
  const { fields, append, remove } = useFieldArray({
    control,
    name: "regions" as const
  })
  const [warmUpGameServer, { isSuccess, isLoading }] =
    useWarmUpGameServerMutation()

  const onSubmitHandler = (data: GameServerBuildWarmUpRequest) => {
    warmUpGameServer(data)
  }

  const onCloseHandler = useCallback(() => {
    resetForm()
    setOpen(false)
  }, [resetForm, setOpen])

  useEffect(() => {
    if (isSuccess) {
      onCloseHandler()
    }
  }, [isSuccess, onCloseHandler])

  useEffect(() => {
    setValue("spaceId", spaceId)
  }, [spaceId, setValue])

  return (
    <Modal
      open={open}
      onClose={onCloseHandler}
      title={t("label.gameServerBuild.warmUpGameServer")}
      sx={{
        "--ModalDialog-minWidth": "600px"
      }}
    >
      <Form
        methods={methods}
        loading={isLoading}
        submitLabel={t("action.warm")}
        onSubmit={onSubmitHandler}
      >
        <FormInput
          label={t("label.gameServerBuild.hoursWarmup")}
          type="number"
          name="hoursWarmup"
          endDecorator={<>{t("time.unit.hour_other")}</>}
          rules={{ required: true, min: 1, max: 12 }}
          slotProps={{ input: { min: 1, max: 12 } }}
        />
        <Table
          variant="plain"
          borderAxis="none"
          sx={{
            maxWidth: "600px",
            "--FormLabel-asteriskColor": ({ palette }) => palette.danger[500],
            "--TableCell-height": "auto",
            "--TableCell-headBackground": "transparent",
            "--TableCell-paddingX": ({ spacing }) => spacing(0.5),
            "& thead th:last-of-type": {
              width: 80
            },
            "& td": {
              verticalAlign: "top"
            }
          }}
        >
          <thead>
            <tr>
              <th>
                <FormLabel required id="region">
                  {t("item.region")}
                </FormLabel>
              </th>
              <th style={{ width: "125px" }}>
                <FormLabel required id="maxIdleCount">
                  {t("label.gameServerBuild.maxIdleCount")}
                </FormLabel>
              </th>
              <th style={{ width: "125px" }}>
                <FormLabel required id="minIdleCount">
                  {t("label.gameServerBuild.minIdleCount")}
                </FormLabel>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {fields.map((field, i) => {
              return (
                <tr key={field.id}>
                  <td>
                    <FormSelect
                      aria-labelledby="region"
                      name={`regions.${i}.regionName`}
                      rules={{
                        required: true,
                        validate: (value) =>
                          value !== "placeholder" ||
                          t(t("error.gameServerBuild.noRegion"))
                      }}
                      options={Object.keys(GameServerRegions).map((region) => ({
                        value: region,
                        label: t(
                          `${GameServerRegions[region as keyof typeof GameServerRegions]}`
                        )
                      }))}
                      sx={{ flexGrow: 1 }}
                    />
                  </td>
                  <td>
                    <FormInput
                      type="number"
                      aria-labelledby="maxIdleCount"
                      name={`regions.${i}.maxIdleCount`}
                      rules={{ required: true, min: 1 }}
                      slotProps={{ input: { min: 1 } }}
                    />
                  </td>
                  <td>
                    <FormInput
                      type="number"
                      aria-labelledby="minIdleCount"
                      name={`regions.${i}.minIdleCount`}
                      rules={{
                        required: true,
                        min: 1,
                        validate: (value) => {
                          const max = getValues(`regions.${i}.maxIdleCount`)
                          return (
                            (!!max && value <= max) ||
                            t("error.gameServerBuild.targetMax")
                          )
                        }
                      }}
                      slotProps={{ input: { min: 1 } }}
                    />
                  </td>
                  <td>
                    <Stack sx={{ flexDirection: "row", gap: 1 }}>
                      <IconButton
                        size="sm"
                        variant="outlined"
                        title={t("action.item", {
                          action: "action.add",
                          item: "item.region"
                        })}
                        aria-label={t("action.item", {
                          action: "action.add",
                          item: "item.region"
                        })}
                        onClick={() => {
                          append({
                            regionName:
                              "placeholder" as keyof typeof GameServerRegions,
                            minIdleCount: null,
                            maxIdleCount: null
                          })
                        }}
                        sx={{ bgcolor: "background.surface" }}
                      >
                        <Plus />
                      </IconButton>
                      {i > 0 && (
                        <IconButton
                          size="sm"
                          variant="outlined"
                          title={t("action.item", {
                            action: "action.remove",
                            item: "item.region"
                          })}
                          aria-label={t("action.item", {
                            action: "action.remove",
                            item: "item.region"
                          })}
                          onClick={() => {
                            remove(i)
                          }}
                        >
                          <Minus />
                        </IconButton>
                      )}
                    </Stack>
                  </td>
                </tr>
              )
            })}
          </tbody>
        </Table>
      </Form>
    </Modal>
  )
}
