import {
  Season,
  SeasonCreateRequest,
  SeasonDeleteRequest,
  SeasonPublishRequest,
  SeasonRevision,
  SeasonRevisionDetails,
  SeasonUpdateRequest,
  SeasonCreateResponse,
  SeasonUpdateResponse,
  SeasonComponent,
  SeasonComponentItemUpdateRequest,
  SeasonComponentItemDeleteRequest,
  SeasonComponentItemCreateResponse,
  SeasonComponentItemUpdateResponse,
  SeasonComponentItem,
  Challenge,
  ChallengeProgressionTypes,
  Battlepass,
  BattlepassDurationTypes,
  BattlepassXpRequiredTypes
} from "../typings"
import { mockDate } from "./common.mocks"
import { mockGame } from "./game.mocks"

export const mockSeasonComponentItem: SeasonComponentItem = {
  itemId: "itemId",
  componentType: "componentType",
  displayName: "displayName",
  lastModified: mockDate
}

export const mockSeasonComponent: SeasonComponent = {
  componentName: mockSeasonComponentItem.componentType,
  items: [mockSeasonComponentItem]
}

export const mockSeasonRevisionDetails: SeasonRevisionDetails = {
  clientDataVersion: "0",
  duration: "21.00:00:00"
}

export const mockSeasonRevision: SeasonRevision = {
  creationDate: mockDate,
  seasonId: "seasonId",
  seasonName: "seasonName",
  gameId: mockGame.gameId,
  isDraft: true,
  isLatestPublished: false,
  revision: 0,
  seasonRevisionDetails: mockSeasonRevisionDetails
}

export const mockSeason: Season = {
  creationDate: mockDate,
  gameId: mockSeasonRevision.gameId,
  seasonId: mockSeasonRevision.seasonId,
  seasonName: mockSeasonRevision.seasonName,
  latestPublishedRevision: -1,
  latestDraftRevision: 0
}

export const mockChallenge: Challenge = {
  displayName: "displayName",
  scope: "scope",
  filter: "filter",
  progressType: ChallengeProgressionTypes.HIGHEST,
  progressInMenu: false,
  levels: [
    {
      threshold: "100",
      reward: "reward"
    }
  ]
}

export const mockBattlepass: Battlepass = {
  displayName: "displayName",
  duration: {
    type: BattlepassDurationTypes.FULL,
    delay: 0,
    length: 6
  },
  xpRequired: {
    type: BattlepassXpRequiredTypes.LINEAR,
    linearValue: 100
  },
  tracks: [
    {
      displayName: "track.0",
      levels: [
        {
          index: 0,
          rewards: [
            { rewardId: "track.0.level.0.reward.0.rewardId" },
            { rewardId: "track.0.level.0.reward.1.rewardId" }
          ]
        },
        {
          index: 1,
          rewards: [{ rewardId: "track.0.level.1.reward.0.rewardId" }]
        },
        {
          index: 2,
          rewards: [{ rewardId: "track.0.level.2.reward.0.rewardId" }]
        },
        {
          index: 3,
          rewards: [{ rewardId: "track.0.level.3.reward.0.rewardId" }]
        },
        {
          index: 4,
          rewards: [{ rewardId: "track.0.level.4.reward.0.rewardId" }]
        }
      ]
    },
    {
      displayName: "track.1",
      levels: [
        {
          index: 0,
          rewards: [
            { rewardId: "track.1.level.0.reward.0.rewardId" },
            { rewardId: "track.1.level.0.reward.1.rewardId" }
          ]
        },
        {
          index: 1,
          rewards: [{ rewardId: "track.1.level.1.reward.0.rewardId" }]
        },
        {
          index: 2,
          rewards: [{ rewardId: "track.1.level.2.reward.0.rewardId" }]
        },
        {
          index: 3,
          rewards: [{ rewardId: "track.1.level.3.reward.0.rewardId" }]
        },
        {
          index: 4,
          rewards: [{ rewardId: "track.1.level.4.reward.0.rewardId" }]
        }
      ]
    }
  ]
}

// REQUEST

export const mockSeasonCreateRequest: SeasonCreateRequest = {
  gameId: mockGame.gameId,
  seasonId: mockSeason.seasonId,
  seasonName: mockSeason.seasonName,
  seasonRevisionDetails: mockSeasonRevisionDetails
}

export const mockSeasonDeleteRequest: SeasonDeleteRequest = {
  gameId: mockGame.gameId,
  seasonId: mockSeason.seasonName
}

export const mockSeasonPublishRequest: SeasonPublishRequest = {
  gameId: mockGame.gameId,
  seasonId: mockSeason.seasonName,
  revision: mockSeasonRevision.revision
}

export const mockSeasonUpdateRequest: SeasonUpdateRequest = {
  gameId: mockGame.gameId,
  seasonId: mockSeason.seasonName,
  seasonRevisionDetails: {
    clientDataVersion: "1",
    duration: "28.00:00:00"
  }
}

export const mockSeasonComponentItemUpdateRequest: SeasonComponentItemUpdateRequest =
  {
    gameId: mockGame.gameId,
    seasonId: mockSeason.seasonId,
    ...mockSeasonComponentItem
  }

export const mockSeasonComponentItemDeleteRequest: SeasonComponentItemDeleteRequest =
  {
    gameId: mockGame.gameId,
    seasonId: mockSeason.seasonId,
    itemId: mockSeasonComponentItem.itemId,
    componentType: mockSeasonComponentItem.componentType
  }

// RESPONSE

export const mockSeasonCreateResponse: SeasonCreateResponse = {
  ...mockSeasonRevision,
  seasonRevisionDetails: mockSeasonRevisionDetails
}

export const mockSeasonUpdateResponse: SeasonUpdateResponse = {
  ...mockSeasonRevision,
  seasonRevisionDetails: mockSeasonRevisionDetails
}

export const mockSeasonComponentItemCreateResponse: SeasonComponentItemCreateResponse =
  mockSeasonComponentItem

export const mockSeasonComponentItemUpdateResponse: SeasonComponentItemUpdateResponse =
  {
    ...mockSeasonComponentItem,
    lastModified: mockSeasonComponentItem.lastModified
  }
