import {
  ApiError,
  CreateActiveDeploymentMutation,
  Deployment,
  GetActiveDeploymentQuery
} from "@liveops-portal/lib"
import notify from "@/helpers/notify"
import i18next from "@/i18n"
import { api } from "@/store/api/index"

export const deploymentApi = api
  .enhanceEndpoints({ addTagTypes: ["Deployment"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getActiveDeployment: builder.query<Deployment, GetActiveDeploymentQuery>({
        providesTags: ["Deployment"],
        query: ({ gameId, spaceId }) => ({
          url: "deployments/active",
          method: "GET",
          params: { gameId, spaceId }
        }),
        onQueryStarted(_, { queryFulfilled }) {
          queryFulfilled.catch(({ error }) => {
            notify.error({
              message: i18next.t("api.getActiveDeployment.error"),
              error: error as ApiError
            })
          })
        }
      }),
      createActiveDeployment: builder.mutation<
        Deployment,
        CreateActiveDeploymentMutation
      >({
        query: ({
          gameId,
          spaceId,
          bundleId,
          bundleVersion,
          qdOperationReason
        }) => ({
          url: "deployments/active",
          method: "POST",
          body: { bundleId, bundleVersion },
          params: { gameId, spaceId },
          headers: {
            "QD-Operation-Reason": qdOperationReason
          }
        }),
        invalidatesTags: ["Deployment"],
        onQueryStarted(_, { queryFulfilled }) {
          queryFulfilled
            .then(() => {
              notify.success(i18next.t("api.createActiveDeployment.success"))
            })
            .catch(({ error }) => {
              notify.error({
                message: i18next.t("api.createActiveDeployment.error"),
                error: error as ApiError
              })
            })
        }
      })
    })
  })

export const {
  useGetActiveDeploymentQuery,
  useCreateActiveDeploymentMutation
} = deploymentApi
