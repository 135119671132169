import { Card, CardContent, Stack, Typography } from "@mui/joy"
import { Grid2 as Grid } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"
import { useAppSelector } from "@/hooks/store"
import { useFindPlayerProfileQuery } from "@/store/api/player"
import { selectSpace, selectPlayer } from "@/store/slices/context"

export const PlayerCustomizations: React.FC = () => {
  const { t } = useTranslation()
  const spaceId = useAppSelector(selectSpace)
  const { type, id } = useAppSelector(selectPlayer)
  const { currentData: playerProfile } = useFindPlayerProfileQuery(
    { spaceId, id, type },
    { skip: !spaceId || !type || !id }
  )

  return (
    !!playerProfile && (
      <Grid size={{ xs: 12, lg: 6 }} component={Stack} sx={{ gap: 2 }}>
        <Typography level="h3">{t("item.customizations")}</Typography>
        <Card
          variant="outlined"
          sx={{ minHeight: 87, overflow: "hidden", flexGrow: 1 }}
        >
          <CardContent sx={{ gap: 2 }}>
            <Typography color="warning" fontStyle="italic">
              {t("message.notImplemented.generic")}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    )
  )
}
