import { Operation } from "@liveops-portal/lib"
import { Button } from "@mui/joy"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { OperationReason } from "@/components/operation-reason/operation-reason"
import { isOperationActive } from "@/helpers/operation-reason"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import {
  useDeletePlayerDataMutation,
  useFindPlayerProfileQuery
} from "@/store/api/player"
import {
  setActiveOperation,
  resetOperation,
  selectActiveOperation
} from "@/store/slices/audit"
import { selectPlayer, selectSpace } from "@/store/slices/context"

export const PlayerDataDelete: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const spaceId = useAppSelector(selectSpace)
  const { type, id } = useAppSelector(selectPlayer)
  const activeOperation = useAppSelector(selectActiveOperation)
  const { currentData: playerProfile } = useFindPlayerProfileQuery(
    { spaceId, id, type },
    { skip: !spaceId || !type || !id }
  )

  const [deletePlayerData, deletePlayerDataMeta] = useDeletePlayerDataMutation()
  const deletePlayerDataOperation: Operation = ["deletePlayerData", "reset"]
  const isDeletePlayerDataOperationActive = isOperationActive(
    activeOperation,
    deletePlayerDataOperation
  )
  const isLoading =
    isDeletePlayerDataOperationActive || deletePlayerDataMeta.isLoading

  const onDeletePlayerDataHandler = () => {
    if (isDeletePlayerDataOperationActive && playerProfile?.accountId) {
      deletePlayerData({
        spaceId,
        id: playerProfile.accountId
      })
    }
  }

  useEffect(() => {
    if (deletePlayerDataMeta.isSuccess || deletePlayerDataMeta.isError) {
      dispatch(resetOperation())
    }
  }, [dispatch, deletePlayerDataMeta.isError, deletePlayerDataMeta.isSuccess])

  return (
    !!playerProfile && (
      <>
        <OperationReason
          open={isDeletePlayerDataOperationActive}
          onSubmit={onDeletePlayerDataHandler}
          autofocus
          closeable
        />

        <Button
          variant="soft"
          color="danger"
          loading={isLoading}
          disabled={isLoading || !playerProfile.data}
          onClick={() => {
            dispatch(setActiveOperation(deletePlayerDataOperation))
          }}
        >
          {t("label.deletePlayerData")}
        </Button>
      </>
    )
  )
}
