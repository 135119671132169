import {
  ConfigCategory,
  ConfigForSpaceId,
  findValue
} from "@liveops-portal/lib"
import {
  Chip,
  ColorPaletteProp,
  List,
  ListItem,
  Stack,
  Typography
} from "@mui/joy"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { CopyToClipboard } from "@/components/copy-to-clipboard/copy-to-clipboard"
import { ExternalLink } from "@/components/external-link/external-link"
import { Spinner } from "@/components/spinner/spinner"
import { useAppSelector } from "@/hooks/store"
import { useFindAllConfigsQuery } from "@/store/api/config"
import { useGetAllGamesQuery } from "@/store/api/game"
import { useFindAllSpacesQuery } from "@/store/api/space"
import { selectEnv, selectSpace, selectGame } from "@/store/slices/context"

export const OverviewEntity: React.FC = () => {
  const { t } = useTranslation()
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const spaceId = useAppSelector(selectSpace)
  const spaces = useFindAllSpacesQuery({ env }, { skip: !env })
  const games = useGetAllGamesQuery({ env }, { skip: !env })
  const { data: configurations, isFetching } = useFindAllConfigsQuery(
    { spaceId },
    { skip: !spaceId }
  )
  const config: ConfigForSpaceId = configurations

  const entity:
    | {
        type: string
        name: string
        uuid: string
        prefix?: string
        playfab?: ConfigCategory
        unimatrix?: ConfigCategory
        unipublish?: ConfigCategory
        udata?: ConfigCategory
      }
    | undefined = useMemo(() => {
    return spaceId && config
      ? spaces?.data
          ?.filter((s) => s.spaceId === spaceId)
          .map((s) => ({
            type: "space",
            name: s.spaceName,
            uuid: s.spaceId,
            prefix: findValue(config, "general.public.spacePrefix"),
            playfab: findValue(config, "playfab"),
            udata: findValue(config, "udata"),
            unipublish: findValue(config, "unipublish"),
            unimatrix: findValue(config, "unimatrix")
          }))[0]
      : games?.data
          ?.filter((g) => g.gameId === gameId)
          .map((g) => ({
            type: "game",
            name: g.name,
            uuid: g.gameId
          }))[0]
  }, [spaceId, spaces.data, gameId, games.data, config])

  const renderServiceLink = (href?: string) =>
    href ? (
      <ExternalLink href={href}>{t("action.dashboard")}</ExternalLink>
    ) : (
      <>❌ {t("state.notConfigured")}</>
    )

  return (
    entity && (
      <Stack sx={{ gap: 2 }}>
        <Typography
          data-testid="name"
          level="h3"
          alignItems="center"
          endDecorator={
            <Chip color={entity.type as ColorPaletteProp} sx={{ mb: "-5px" }}>
              {entity?.type}
            </Chip>
          }
        >
          {entity.name}
        </Typography>
        <List
          sx={{
            p: 0,
            "--ListItem-minHeight": 0,
            "--ListItem-paddingX": 0
          }}
        >
          <Spinner loading={isFetching} />
          <ListItem>
            <Typography level="title-sm">{t("id.uuid")}</Typography>
            <Typography level="body-sm" data-testid="uuid">
              <CopyToClipboard>{entity.uuid}</CopyToClipboard>
            </Typography>
          </ListItem>
          {entity.type === "space" && (
            <>
              <ListItem>
                <Typography level="title-sm">
                  {t("modifier.prefix", { item: "item.space" })}
                </Typography>
                <Typography level="body-sm" data-testid="prefix">
                  {entity.prefix || `❌ ${t("state.notConfigured")}`}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography level="title-sm">{t("service.playfab")}</Typography>
                <Typography level="body-sm" data-testid="playfab">
                  {renderServiceLink(
                    entity.playfab &&
                      `https://developer.playfab.com/en-US/${findValue(entity.playfab.public, "titleId")?.toString()}/dashboard`
                  )}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography level="title-sm">{t("service.udata")}</Typography>
                <Typography level="body-sm" data-testid="udata">
                  {renderServiceLink(
                    entity.udata && `https://udata.nie.easebar.com/bi/dashboard`
                  )}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography level="title-sm">
                  {t("service.unimatrix")}
                </Typography>
                <Typography level="body-sm" data-testid="unimatrix">
                  {renderServiceLink(
                    entity.unimatrix &&
                      `https://unisdk.nie.easebar.com/unimatrix/${findValue(entity.unimatrix.private, "gameId")?.toString()}/${findValue(entity.unimatrix.private, "appId")?.toString()}/instance-details`
                  )}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography level="title-sm">
                  {t("service.unipublish")}
                </Typography>
                <Typography level="body-sm" data-testid="unipublish">
                  {renderServiceLink(
                    entity.unipublish &&
                      "https://easepublish.nie.easebar.com/game/2314/3"
                  )}
                </Typography>
              </ListItem>
            </>
          )}
        </List>
      </Stack>
    )
  )
}
