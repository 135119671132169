export class Deck {
  id: string
  name: string
  spells: string[]
}

export class Customization {
  spellcasterType: string
  customizations: unknown[]
}

export class CatalystDeck {
  spellcasterType: string
  decks: Deck[]
}

export class PlayerCustomization {
  spellcasterType: string
  customizations: Customization[]
}

export class LastDeckUsed {
  spellcasterType: string
  lastDeckUsed: Deck["id"]
}

export class LastCatalystUsed {
  spellcasterType: string
  lastCatalystUsed: string
}

export class PlayerProfile {
  accountId: string
  playtime: number
  lastOnline: number
  PlayfabProfileId?: string
  data: {
    ranking?: string
    lastSpellcasterPlayed: string
    decks: CatalystDeck[]
    lastDecksUsed: LastDeckUsed[]
    lastCatalystsUsed: LastCatalystUsed[]
    customizations?: PlayerCustomization[]
  }
}

export enum PlayerIdType {
  accountId = "accountId",
  xbl = "xbl",
  psn = "psn",
  steam = "steam"
}

export type PlayerIds = {
  [key in PlayerIdType]: string
}

export interface PlayerId {
  id: string | null
  type: keyof typeof PlayerIdType | null
}

export type ByPlayerIdQuery = PlayerId
