import { combineSlices } from "@reduxjs/toolkit"
import { api } from "@/store/api"
import { appSlice } from "@/store/slices/app"
import { auditSlice } from "@/store/slices/audit"
import { contextSlice } from "@/store/slices/context"
import { filteringSlice } from "@/store/slices/filtering"

export const rootReducer = combineSlices(
  api,
  appSlice,
  auditSlice,
  contextSlice,
  filteringSlice
)
