import { ToolboxRequest } from "@liveops-portal/lib"
import {
  Chip,
  ListItem,
  ListItemButton,
  ListItemDecorator,
  Typography
} from "@mui/joy"
import React from "react"

interface Props {
  toolboxRequest: Partial<ToolboxRequest>
  onClick: () => void
}

export const ToolboxHistoryItem: React.FC<Props> = ({
  toolboxRequest,
  onClick
}) => {
  const { method, serviceName, endpoint } = toolboxRequest

  return (
    <ListItem>
      <ListItemButton
        onClick={onClick}
        sx={{ alignItems: "flex-start" }}
        data-testid="historyItem"
      >
        <ListItemDecorator
          sx={{ width: "60px", justifyContent: "center", mt: "2px" }}
        >
          <Chip>{method}</Chip>
        </ListItemDecorator>
        <Typography
          sx={{ display: { xs: "flex", xl: "block" }, flexDirection: "column" }}
        >
          <Typography
            level="body-sm"
            fontWeight="xl"
            textColor="text.secondary"
            sx={{ mr: 1 }}
          >
            {serviceName}
          </Typography>
          <Typography level="body-xs">{endpoint}</Typography>
        </Typography>
      </ListItemButton>
    </ListItem>
  )
}
