import { ActiveFilters, Filter } from "@liveops-portal/lib"
import { Button, Input, Stack } from "@mui/joy"
import { Search } from "iconoir-react"
import {
  ChangeEventHandler,
  MouseEventHandler,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react"
import { useSearchParams } from "react-router-dom"
import { FiltersSelect } from "@/components/filters-select/filters-select"
import { isValidParam } from "@/helpers/params"
import { useAppDispatch } from "@/hooks/store"
import { setFilteringResults } from "@/store/slices/filtering"

interface Props<T> {
  items: T[]
  filters: Filter<T>[]
  searchable?: string[]
}

export const Filters = <T,>({ items, filters, searchable }: Props<T>) => {
  const dispatch = useAppDispatch()
  const [searchParams, setSearchParams] = useSearchParams()
  const [activeFilters, setActiveFilters] = useState<ActiveFilters<T>>()
  const [search, setSearch] = useState<string>("")
  const mounted = useRef(false)

  const isFiltered = useMemo(
    () =>
      (activeFilters &&
        Object.values<string[]>(activeFilters).some(
          (filter) => !!filter.length
        )) ||
      search,
    [activeFilters, search]
  )

  const initialFilters = useMemo(
    () =>
      filters.reduce(
        (acc, { field }) => ({ ...acc, [field]: [] }),
        {} as ActiveFilters<T>
      ),
    [filters]
  )

  const onInputChangeHandler: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    setSearchParams((params) => {
      event.target.value.length
        ? params.set("search", event.target.value)
        : params.delete("search")
      return params
    })
    setSearch(event.target.value)
  }

  const onSelectChangeHandler: ChangeEventHandler<HTMLInputElement> = (
    event
  ) => {
    if (activeFilters) {
      const { value, id: field } = event.target
      const key = field as keyof T
      const filter = activeFilters[key].slice()
      const updatedFilter = filter.includes(value)
        ? filter.filter((i) => i !== value)
        : [...filter, value]
      const param = updatedFilter.join(", ")

      setActiveFilters({
        ...activeFilters,
        [key]: updatedFilter
      })

      setSearchParams((params) => {
        param.length ? params.set(field, param) : params.delete(field)
        return params
      })
    }
  }

  const onResetHandler: MouseEventHandler = () => {
    setSearchParams((params) => {
      params.delete("search")
      Object.keys(initialFilters).forEach((field) => {
        params.delete(field)
      })

      return params
    })

    setActiveFilters(initialFilters)
    setSearch("")
  }

  const initFilters = useCallback(() => {
    if (mounted.current) return
    const searchParam = searchParams.get("search")

    Object.keys(initialFilters).forEach((field) => {
      const key = field as keyof T
      const paramValues = searchParams.getAll(field)[0]
      const filter = filters.find((f) => f.field === field)

      if (paramValues) {
        const validValues = paramValues
          .split(", ")
          .reduce(
            (acc, param) =>
              isValidParam(param, Object.keys(filter?.options))
                ? [...acc, param]
                : acc,
            [] as string[]
          )

        setSearchParams((params) => {
          validValues.length
            ? params.set(field, validValues.join(","))
            : params.delete(field)

          return params
        })

        // Param exists and is valid, use it as initial value
        initialFilters[key] = validValues
      }
    })

    searchParam && setSearch(searchParam)
    setActiveFilters(initialFilters)
    mounted.current = true
  }, [filters, initialFilters, searchParams, setSearchParams])

  const doFiltering = useCallback(() => {
    if (!activeFilters) return

    let _items: T[] = items
    if (searchable && search) {
      _items = _items.filter((item) =>
        searchable.some((field) =>
          (item[field as keyof T] as string)?.includes(search)
        )
      )
    }

    if (Object.keys(activeFilters)) {
      Object.keys(activeFilters).forEach((field) => {
        const key = field as keyof T
        if (activeFilters[key]?.length) {
          _items = _items.filter((item) =>
            activeFilters[key]?.includes(item[key] as string)
          )
        }
      })
    }

    dispatch(setFilteringResults(_items))
  }, [activeFilters, items, searchable, search, dispatch])

  useEffect(() => {
    initFilters()
  }, [initFilters])

  useEffect(() => {
    doFiltering()
  }, [doFiltering])

  return (
    activeFilters && (
      <Stack direction="row" gap={2}>
        {!!searchable && (
          <Input
            type="text"
            endDecorator={<Search />}
            value={search}
            onChange={onInputChangeHandler}
          />
        )}

        {filters.map(({ label, options, field }) => (
          <FiltersSelect
            key={label}
            label={label}
            field={field}
            options={options}
            selected={activeFilters[field]}
            onChange={onSelectChangeHandler}
          />
        ))}

        {isFiltered && (
          <Button
            aria-label="Reset filters"
            variant="outlined"
            onClick={onResetHandler}
          >
            Reset filters
          </Button>
        )}
      </Stack>
    )
  )
}
