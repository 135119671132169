import {
  FindAllGameDataVersionsRequest,
  ApiError,
  GameDataVersion,
  findAllGameDataTypesRequest,
  FindAllGameDataRequest,
  GameData
} from "@liveops-portal/lib"
import notify from "@/helpers/notify"
import i18next from "@/i18n"
import { api } from "@/store/api"

export const gameDataApi = api.injectEndpoints({
  endpoints: (builder) => ({
    findAllGameDataVersions: builder.query<
      string[],
      FindAllGameDataVersionsRequest
    >({
      query: ({ gameId }) => ({
        url: `/game/${gameId}/data-versions`
      }),
      onQueryStarted({ gameId }, { queryFulfilled }) {
        queryFulfilled.catch(({ error }) => {
          notify.error({
            message: i18next.t("api.findAllGameDataVersions.error", { gameId }),
            error: error as ApiError
          })
        })
      },
      transformResponse: (gameDataVersions: GameDataVersion[]) => {
        return gameDataVersions
          ?.filter(({ state }) => state === "READY_FOR_USE")
          .map(({ dataVersion }) => dataVersion.toString())
      }
    }),
    findAllGameDataTypes: builder.query<string[], findAllGameDataTypesRequest>({
      query: ({ gameId, dataVersion }) => ({
        url: `/game/${gameId}/data-version/${dataVersion}/data-types`
      }),
      onQueryStarted({ gameId, dataVersion }, { queryFulfilled }) {
        queryFulfilled.catch(({ error }) =>
          notify.error({
            message: i18next.t("api.findAllGameDataTypes.error", {
              gameId,
              dataVersion
            }),
            error: error as ApiError
          })
        )
      }
    }),
    findAllGameData: builder.query<GameData, FindAllGameDataRequest>({
      query: ({ gameId, dataVersion, dataType }) => ({
        url: `/game/${gameId}/data-version/${dataVersion}/data-type/${dataType}`
      }),
      onQueryStarted({ gameId, dataVersion, dataType }, { queryFulfilled }) {
        queryFulfilled.catch(({ error }) =>
          notify.error({
            message: i18next.t("api.findAllGameData.error", {
              gameId,
              dataVersion,
              dataType
            }),
            error: error as ApiError
          })
        )
      }
    })
  }),
  overrideExisting: false
})

export const {
  useFindAllGameDataVersionsQuery,
  useFindAllGameDataTypesQuery,
  useFindAllGameDataQuery
} = gameDataApi
