import { Card, FormControl, FormLabel, Input, Stack } from "@mui/joy"
import { default as JoyTable } from "@mui/joy/Table"
import { TablePagination } from "@mui/material"
import {
  ColumnDef,
  ColumnFiltersState,
  ExpandedState,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  Row,
  useReactTable
} from "@tanstack/react-table"
import { ReactNode, useMemo, useState } from "react"
import { TableHeader } from "@/components/table/table-header/table-header"
import { TableRow } from "@/components/table/table-row/table-row"

interface Props<T> {
  data: T[]
  filters?: { label: string; accessor: string }[]
  pageSize?: number
  rowsPerPage?: number[]
  renderCustomSubRow?: (row: Row<T>) => ReactNode
}

export const Table = <T extends object>({
  data,
  filters,
  pageSize = 20,
  rowsPerPage = [20, 50, 100],
  renderCustomSubRow
}: Props<T>) => {
  // TanStack necessary states for features
  const [expanded, setExpanded] = useState<ExpandedState>({})
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize
  })

  const columns = useMemo<ColumnDef<T>[]>(
    () =>
      data.length
        ? Object.keys(data[0]).map((key) => ({
            header: key,
            accessorKey: key
          }))
        : [],
    [data]
  )

  const table = useReactTable<T>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    state: {
      columnFilters,
      expanded,
      pagination
    },
    onColumnFiltersChange: setColumnFilters,
    onExpandedChange: setExpanded,
    onPaginationChange: setPagination
  })

  return (
    <Card>
      <Stack gap={1} direction="row">
        {filters?.map(({ accessor, label }) => (
          <FormControl key={accessor}>
            <FormLabel>{label}</FormLabel>
            <Input
              name={accessor}
              onChange={(e) => {
                table.getColumn(accessor)?.setFilterValue(e.target.value)
              }}
            />
          </FormControl>
        ))}
      </Stack>

      <JoyTable
        noWrap
        role="table"
        aria-rowcount={table.getRowModel().rows.length}
      >
        <TableHeader
          hasCollapsibleColumn
          headerGroups={table.getHeaderGroups()}
        />
        <tbody>
          {table.getRowModel().rows.map((row) => (
            <TableRow
              key={row.id}
              row={row}
              renderCustomSubRow={renderCustomSubRow}
            />
          ))}
        </tbody>
      </JoyTable>
      <TablePagination
        component="div"
        count={table.getRowCount()}
        page={table.getState().pagination.pageIndex}
        onPageChange={(_e, pageNumber) => table.setPageIndex(pageNumber)}
        rowsPerPageOptions={rowsPerPage}
        onRowsPerPageChange={(e) => table.setPageSize(parseInt(e.target.value))}
        rowsPerPage={table.getState().pagination.pageSize}
      />
    </Card>
  )
}
