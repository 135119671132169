import { mockGame, mockFindAllGamesResponse } from "../mocks"
import { Space, AllSpacesQuery, BySpaceIdQuery } from "../typings"

export const mockSpace: Space = {
  spaceId: "ba142a82-9513-11ee-b9d1-0242ac120002",
  gameId: mockGame.gameId,
  spaceName: "spaceName-1"
}

export const mockFindAllSpacesRequest: AllSpacesQuery = {
  gameId: mockGame.gameId
}

export const mockFindAllSpacesResponse: Space[] = [
  ...[mockSpace],
  {
    spaceId: "cc6a0286-24fd-452c-a14a-797838f5472b",
    gameId: mockGame.gameId,
    spaceName: "spaceName-2"
  },
  {
    spaceId: "ff1c8af6-6119-4df8-8b05-209bde32e2de",
    gameId: mockFindAllGamesResponse[1].gameId,
    spaceName: "spaceName-3"
  }
]

export const mockFindAllSpacesForGameIdResponse: Space[] =
  mockFindAllSpacesResponse.filter(({ gameId }) => gameId === mockGame.gameId)

export const mockFindSpaceRequest: BySpaceIdQuery = {
  spaceId: mockSpace.spaceId
}

export const mockFindSpaceResponse: Space = mockFindAllSpacesResponse[0]
