import { formatJsonToString } from "@liveops-portal/lib"
import { List, ListItem, Stack, Typography } from "@mui/joy"
import React from "react"
import { useTranslation } from "react-i18next"
import { CodeEditor } from "@/components/code-editor/code-editor"
import { CopyToClipboard } from "@/components/copy-to-clipboard/copy-to-clipboard"
import { Spinner } from "@/components/spinner/spinner"
import { useAppSelector } from "@/hooks/store"
import { useGetGameDataBySeasonIdQuery } from "@/store/api/game-data"
import { useGetSeasonsQuery } from "@/store/api/season"
import { selectEnv, selectGame, selectSeason } from "@/store/slices/context"

/**
 * Component representing the Season Designer DataViewer page.
 */
export const SeasonDesignerDataViewerPage: React.FC = () => {
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const seasonId = useAppSelector(selectSeason)
  const { t } = useTranslation()

  const { season, isFetching: isFetchingSeasons } = useGetSeasonsQuery(
    { gameId, env },
    {
      skip: !gameId || !env,
      selectFromResult: (result) => ({
        ...result,
        season: result.data?.find((season) => season.seasonId === seasonId)
      })
    }
  )
  const { clientDataVersion } = season || {}

  const {
    data: gameData,
    isUninitialized: isUninitializedGameData,
    isFetching: isFetchingGameData
  } = useGetGameDataBySeasonIdQuery(
    { gameId, seasonId, env },
    { skip: !seasonId || !gameId || !env }
  )

  const isFetching = isFetchingSeasons || isFetchingGameData

  return isUninitializedGameData ? (
    <Typography>
      {t("message.uninitialized.parent", {
        parent: "item.season",
        item: "item.gameData"
      })}
    </Typography>
  ) : (
    <>
      <Spinner loading={isFetching} />

      {gameData && Object.values(gameData).length && (
        <Stack gap={0.5} flexGrow={1}>
          <List
            sx={{
              p: 0,
              flexGrow: 0,
              "--ListItem-minHeight": 0,
              "--ListItem-paddingX": 0
            }}
          >
            <ListItem>
              <Typography level="title-sm">{t("item.dataVersion")}</Typography>
              <Typography level="body-sm">
                <CopyToClipboard>{clientDataVersion}</CopyToClipboard>
              </Typography>
            </ListItem>
          </List>
          <Stack flexGrow={1} gap={1} direction="row" position="relative">
            <CodeEditor
              editable={false}
              name="gameData"
              value={formatJsonToString(gameData)}
              label={t("item.gameData")}
              sx={{ flexBasis: "100%" }}
            />
          </Stack>
        </Stack>
      )}
    </>
  )
}
