import { Space } from "@liveops-portal/lib"
import { Card, CardContent, Chip, Table, Typography } from "@mui/joy"
import { Grid2 as Grid } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"
import { CopyToClipboard } from "@/components/copy-to-clipboard/copy-to-clipboard"

interface Props {
  buildId: string
  space?: Space
}

export const ApplicationCard: React.FC<Props> = ({ buildId, space }: Props) => {
  const { t } = useTranslation()

  return (
    <Grid size={{ xs: 6, lg: 4 }}>
      <Card variant="outlined">
        <CardContent>
          <Table
            borderAxis="none"
            sx={{ "--TableCell-headBackground": "transparent" }}
          >
            <tbody>
              <tr>
                <th scope="row" style={{ width: "100px" }}>
                  {t("id.build")}
                </th>
                <td>
                  {buildId === "default" ? (
                    <Chip>{buildId}</Chip>
                  ) : (
                    <CopyToClipboard>
                      <Typography level="body-sm">{buildId}</Typography>
                    </CopyToClipboard>
                  )}
                </td>
              </tr>
              <tr>
                <th
                  scope="row"
                  style={{ width: "100px", verticalAlign: "top" }}
                >
                  {t("item.space")}
                </th>
                <td>
                  <Typography fontSize="sm">{space?.spaceName}</Typography>
                  <CopyToClipboard>
                    <Typography level="body-xs" textColor="text.tertiary">
                      {space?.spaceId}
                    </Typography>
                  </CopyToClipboard>
                </td>
              </tr>
            </tbody>
          </Table>
        </CardContent>
      </Card>
    </Grid>
  )
}
