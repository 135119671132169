import { AllGamesQuery, ByGameIdQuery, Game } from "../typings"

export const mockGame: Game = {
  gameId: "5076710c-950e-11ee-b9d1-0242ac120002",
  name: "gameName-1",
  markedForDeletion: false
}

export const mockFindAllGamesRequest: Omit<AllGamesQuery, "env"> = {
  includeMarkedForDeletion: true
}

export const mockFindGameRequest: ByGameIdQuery = {
  gameId: mockGame.gameId
}

export const mockFindGameResponse: Game = mockGame

export const mockFindAllGamesResponse: Game[] = [
  ...[mockGame],
  {
    gameId: "b7a8a1b5-abdd-49aa-bf04-0b942e22dd9c",
    name: "gameName-2",
    markedForDeletion: false
  },
  {
    gameId: "f02d63a0-a2a8-49f6-94c8-30fe29e72f11",
    name: "spellcasters",
    markedForDeletion: false
  }
]
