import {
  AllSeasonsQuery,
  Season,
  SeasonComponentItemCreateRequest,
  SeasonComponentItemCreateResponse,
  SeasonCreateRequest,
  SeasonCreateResponse,
  SeasonDeleteRequest,
  SeasonUpdateRequest,
  SeasonUpdateResponse,
  SeasonPublishRequest,
  formatWeeksToTimeSpan,
  SeasonComponentItemUpdateRequest,
  SeasonComponentItemUpdateResponse,
  SeasonComponentItemDeleteRequest,
  SeasonComponentItem,
  SeasonComponentItemsByTypeQuery,
  ApiError,
  SeasonReferenceDateRequest,
  SeasonReferenceDateResponse
} from "@liveops-portal/lib"
import { v4 as uuidv4 } from "uuid"
import notify from "@/helpers/notify"
import i18next from "@/i18n"
import { api } from "@/store/api"

export const seasonApi = api
  .enhanceEndpoints({
    addTagTypes: ["Season", "SeasonComponentItem", "Bundle"]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getSeasons: builder.query<Season[], AllSeasonsQuery>({
        query: ({ gameId }: AllSeasonsQuery) => ({
          url: "seasons",
          params: { gameId }
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ seasonId }) => ({
                  type: "Season" as const,
                  id: seasonId
                }))
              ]
            : ["Season"],
        onQueryStarted({ gameId }, { queryFulfilled }) {
          queryFulfilled.catch(({ error }) => {
            notify.error({
              message: i18next.t("api.getSeasons.error", { gameId }),
              error: error as ApiError
            })
          })
        }
      }),
      createSeason: builder.mutation<SeasonCreateResponse, SeasonCreateRequest>(
        {
          query: ({
            gameId,
            seasonName,
            seasonRevisionDetails: { duration, clientDataVersion },
            qdOperationReason
          }: SeasonCreateRequest) => {
            const seasonId = uuidv4()

            return {
              url: `seasons/${seasonId}`,
              method: "POST",
              params: { gameId },
              body: {
                seasonName,
                seasonRevisionDetails: {
                  clientDataVersion,
                  duration: formatWeeksToTimeSpan(duration)
                }
              },
              headers: {
                "QD-Operation-Reason": qdOperationReason
              }
            }
          },
          invalidatesTags: ["Season"],
          onQueryStarted(_, { queryFulfilled }) {
            queryFulfilled
              .then(() => {
                notify.success(i18next.t("api.createSeason.success"))
              })
              .catch(({ error }) => {
                notify.error({
                  message: i18next.t("api.createSeason.error"),
                  error: error as ApiError
                })
              })
          }
        }
      ),
      deleteSeason: builder.mutation<void, SeasonDeleteRequest>({
        query: ({ seasonId, gameId }) => ({
          url: `seasons/${seasonId}`,
          params: { gameId },
          method: "DELETE"
        }),
        invalidatesTags: (result, error, { seasonId }) => [
          { type: "Season", id: seasonId }
        ],
        onQueryStarted(_, { queryFulfilled }) {
          queryFulfilled
            .then(() => {
              notify.success(i18next.t("api.deleteSeason.success"))
            })
            .catch(({ error }) => {
              notify.error({
                message: i18next.t("api.deleteSeason.error"),
                error: error as ApiError
              })
            })
        }
      }),
      updateSeason: builder.mutation<SeasonUpdateResponse, SeasonUpdateRequest>(
        {
          query: ({
            gameId,
            seasonId,
            seasonRevisionDetails,
            qdOperationReason
          }: SeasonUpdateRequest) => ({
            url: `seasons/${seasonId}`,
            method: "PUT",
            params: { gameId },
            body: {
              clientDataVersion: seasonRevisionDetails?.clientDataVersion,
              duration:
                seasonRevisionDetails &&
                formatWeeksToTimeSpan(seasonRevisionDetails.duration)
            },
            headers: {
              "QD-Operation-Reason": qdOperationReason
            }
          }),
          invalidatesTags: (result, error, { seasonId }) => [
            { type: "Season", id: seasonId }
          ],
          onQueryStarted(_, { queryFulfilled }) {
            queryFulfilled
              .then(() => {
                notify.success(i18next.t("api.updateSeason.success"))
              })
              .catch(({ error }) => {
                notify.error({
                  message: i18next.t("api.updateSeason.error"),
                  error: error as ApiError
                })
              })
          }
        }
      ),
      publishSeasonRevision: builder.mutation<
        { messages: string[] },
        SeasonPublishRequest
      >({
        query: ({
          seasonId,
          gameId,
          revision,
          referenceDate,
          qdOperationReason
        }: SeasonPublishRequest) => ({
          url: `seasons/${seasonId}/publish`,
          method: "POST",
          params: { gameId },
          body: { revision, referenceDate },
          headers: {
            "QD-Operation-Reason": qdOperationReason
          }
        }),
        invalidatesTags: ["Season", "Bundle"],
        onQueryStarted({ revision }, { queryFulfilled }) {
          queryFulfilled
            .then(({ data }) => {
              notify.success(
                `${i18next.t("api.publishSeasonRevision.success", { revision })}\n${data.messages.join("\n")}`,
                { style: { whiteSpace: "pre-wrap" } }
              )
            })
            .catch(({ error }) => {
              notify.error({
                message: i18next.t("api.publishSeasonRevision.error"),
                error: error as ApiError
              })
            })
        }
      }),
      getSeasonComponentItems: builder.query<
        SeasonComponentItem[],
        SeasonComponentItemsByTypeQuery
      >({
        query: ({ gameId, seasonId, componentType }) => ({
          url: `seasons/${seasonId}/components/${componentType}`,
          params: { gameId }
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ itemId }) => ({
                  type: "SeasonComponentItem" as const,
                  id: itemId
                }))
              ]
            : ["SeasonComponentItem"],
        onQueryStarted({ seasonId, componentType }, { queryFulfilled }) {
          queryFulfilled.catch(({ error }) =>
            notify.error({
              message: i18next.t("api.getSeasonComponentItems.error", {
                componentType: `item.${componentType}`,
                seasonId
              }),
              error: error as ApiError
            })
          )
        }
      }),
      createSeasonComponentItem: builder.mutation<
        SeasonComponentItemCreateResponse,
        SeasonComponentItemCreateRequest
      >({
        query: ({
          gameId,
          seasonId,
          qdOperationReason,
          componentType,
          ...body
        }: SeasonComponentItemCreateRequest) => {
          const itemId = uuidv4()
          return {
            url: `seasons/${seasonId}/components/${componentType}/items/${itemId}`,
            method: "POST",
            params: { gameId },
            body,
            headers: {
              "QD-Operation-Reason": qdOperationReason
            }
          }
        },
        invalidatesTags: (result, error, { itemId }) => [
          { type: "SeasonComponentItem", id: itemId }
        ],
        onQueryStarted({ displayName, componentType }, { queryFulfilled }) {
          queryFulfilled
            .then(() => {
              notify.success(
                i18next.t("api.createSeasonComponentItem.success", {
                  componentType: `item.${componentType}`,
                  displayName
                })
              )
            })
            .catch(({ error }) => {
              notify.error({
                message: i18next.t("api.createSeasonComponentItem.error", {
                  componentType: `item.${componentType}`
                }),
                error: error as ApiError
              })
            })
        }
      }),
      updateSeasonComponentItem: builder.mutation<
        SeasonComponentItemUpdateResponse,
        SeasonComponentItemUpdateRequest
      >({
        query: ({
          gameId,
          seasonId,
          itemId,
          componentType,
          qdOperationReason,
          ...body
        }: SeasonComponentItemUpdateRequest) => ({
          url: `seasons/${seasonId}/components/${componentType}/items/${itemId}`,
          method: "PUT",
          params: { gameId },
          body,
          headers: {
            "QD-Operation-Reason": qdOperationReason
          }
        }),
        invalidatesTags: (result, error, { itemId }) => [
          { type: "SeasonComponentItem", id: itemId }
        ],
        onQueryStarted({ displayName, componentType }, { queryFulfilled }) {
          queryFulfilled
            .then(() => {
              notify.success(
                i18next.t("api.updateSeasonComponentItem.success", {
                  componentType: `item.${componentType}`,
                  displayName
                })
              )
            })
            .catch(({ error }) => {
              notify.error({
                message: i18next.t("api.updateSeasonComponentItem.error", {
                  componentType: `item.${componentType}`
                }),
                error: error as ApiError
              })
            })
        }
      }),
      deleteSeasonComponentItem: builder.mutation<
        void,
        SeasonComponentItemDeleteRequest
      >({
        query: ({
          gameId,
          seasonId,
          itemId,
          componentType,
          qdOperationReason
        }: SeasonComponentItemDeleteRequest) => ({
          url: `seasons/${seasonId}/components/${componentType}/items/${itemId}`,
          method: "DELETE",
          params: { gameId },
          headers: {
            "QD-Operation-Reason": qdOperationReason
          }
        }),
        invalidatesTags: (result, error, { itemId }) => [
          { type: "SeasonComponentItem", id: itemId }
        ],
        onQueryStarted({ componentType }, { queryFulfilled }) {
          queryFulfilled
            .then(() => {
              notify.success(
                i18next.t("api.deleteSeasonComponentItem.success", {
                  componentType: `item.${componentType}`
                })
              )
            })
            .catch(({ error }) => {
              notify.error({
                message: i18next.t("api.deleteSeasonComponentItem.error", {
                  componentType: `item.${componentType}`
                }),
                error: error as ApiError
              })
            })
        }
      }),
      getReferenceDate: builder.query<
        SeasonReferenceDateResponse,
        SeasonReferenceDateRequest
      >({
        query: ({ gameId, seasonId }: SeasonReferenceDateRequest) => ({
          url: `seasons/${seasonId}/referenceDate`,
          method: "GET",
          params: { gameId }
        })
      })
    }),
    overrideExisting: false
  })

export const {
  useCreateSeasonComponentItemMutation,
  useUpdateSeasonComponentItemMutation,
  useDeleteSeasonComponentItemMutation,
  useCreateSeasonMutation,
  useDeleteSeasonMutation,
  usePublishSeasonRevisionMutation,
  useUpdateSeasonMutation,
  useGetSeasonsQuery,
  useGetSeasonComponentItemsQuery,
  useGetReferenceDateQuery
} = seasonApi
