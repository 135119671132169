import { mockApplication, mockSpace } from "../mocks"
import {
  BuildSpaceAssociation,
  BuildSpaceAssociationsByAppId
} from "../typings"

export const mockBuild: BuildSpaceAssociation = {
  appId: mockApplication.appId,
  buildId: "0",
  spaceId: mockSpace.spaceId
}

export const mockAllBuildsBySpaceId: BuildSpaceAssociation[] = [
  {
    spaceId: mockSpace.spaceId,
    appId: mockApplication.appId,
    buildId: "0"
  },
  {
    spaceId: mockSpace.spaceId,
    appId: mockApplication.appId,
    buildId: "default"
  }
]

export const mockAllBuildsByGameId: BuildSpaceAssociationsByAppId = {
  [mockApplication.appId]: mockAllBuildsBySpaceId
}
