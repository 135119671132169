import { Actions, Challenge, SeasonComponentItem } from "@liveops-portal/lib"
import React, { Dispatch, SetStateAction, useCallback, useEffect } from "react"
import { useForm } from "react-hook-form"
import { ChallengeModal } from "@/components/challenge-modal/challenge-modal"
import { useAppSelector } from "@/hooks/store"
import { useUpdateSeasonComponentItemMutation } from "@/store/api/season"
import { selectGame, selectSeason } from "@/store/slices/context"

interface Props extends Omit<SeasonComponentItem<Challenge>, "componentType"> {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

type FormData = SeasonComponentItem<Challenge>

export const ChallengeUpdate: React.FC<Props> = ({
  open,
  setOpen,
  itemId,
  displayName,
  scope,
  filter,
  progressType,
  progressInMenu,
  levels,
  lastModified
}) => {
  const methods = useForm<FormData>({
    mode: "all",
    defaultValues: {
      componentType: "challenge",
      itemId,
      displayName,
      scope,
      filter,
      progressType,
      progressInMenu,
      levels,
      lastModified
    }
  })
  const { reset: resetForm } = methods
  const gameId = useAppSelector(selectGame)
  const seasonId = useAppSelector(selectSeason)

  const [updateChallenge, mutation] = useUpdateSeasonComponentItemMutation()
  const { isSuccess, isLoading } = mutation

  const onCloseHandler = useCallback(() => {
    resetForm()
    setOpen(false)
  }, [resetForm, setOpen])

  const onSubmitHandler = (data: FormData) =>
    updateChallenge({ ...data, gameId, seasonId })

  useEffect(() => {
    if (isSuccess) {
      onCloseHandler()
    }
  }, [isSuccess, onCloseHandler])

  return (
    <ChallengeModal<FormData>
      open={open}
      loading={isLoading}
      action={Actions.update}
      methods={methods}
      onSubmit={onSubmitHandler}
      onClose={onCloseHandler}
    />
  )
}
