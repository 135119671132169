/* eslint-disable @typescript-eslint/no-explicit-any */

/**
 * Wraps an object in an array
 * @typeParam T - Type of object the array contains
 * @param condition
 * @param obj
 */
export const toArray = <T = object>(obj: T): T[] => {
  if (obj == null) {
    return []
  } else if (Array.isArray(obj)) {
    return obj
  } else {
    return [obj]
  }
}

/**
 * Allows to spread in conditionally an object in an array
 * @typeParam T - Type of object the array contains
 * @param condition
 * @param obj
 */
export const insertIf = <T = object>(condition: boolean, obj: T): T[] => {
  return condition ? toArray(obj) : []
}

/**
 * Finds last element of array without mutating the array (as opposed to .pop())
 */
export const last = (array: any[]) => array[array.length - 1]

/**
 * Returns all left array values not included in right array
 */
export const not = (a: (string | number)[], b: (string | number)[]) =>
  a.filter((value) => !b.includes(value))

/**
 * Returns all left array values included in right array
 */
export const intersection = (a: (string | number)[], b: (string | number)[]) =>
  a.filter((value) => b.includes(value))

/**
 * Returns all values contained in left array plus all right array values not already included
 */
export const union = (a: (string | number)[], b: (string | number)[]) => [
  ...a,
  ...not(b, a)
]
