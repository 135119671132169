import { createSlice, PayloadAction } from "@reduxjs/toolkit"
import { GlobalState } from "@/typings/redux"

/* Interface for the state of the filtering feature. */
export interface FilteringState {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  results: any[]
}

/* Redux store slice for the filtering feature. */
export const filteringSlice = createSlice({
  name: "filtering",
  initialState: {
    results: []
  } as FilteringState,
  reducers: {
    setFilteringResults: (
      state: FilteringState,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      { payload: results }: PayloadAction<any[]>
    ) => {
      state.results = results
    }
  }
})

/* Actions related to the filtering feature. */
export const { setFilteringResults } = filteringSlice.actions

/* Selectors related to the filtering feature. */
export const selectFilteringResults = (state: GlobalState) =>
  state.filtering.results

export default filteringSlice.reducer
