import {
  Operation,
  Season,
  formatTimeSpanToWeeks,
  insertIf
} from "@liveops-portal/lib"
import { Button } from "@mui/joy"
import { Eye } from "iconoir-react"
import React, { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { CardItem } from "@/components/card-item/card-item"
import { OperationReason } from "@/components/operation-reason/operation-reason"
import { SeasonUpdate } from "@/components/season-update/season-update"
import { isOperationActive } from "@/helpers/operation-reason"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { useGetGameDataVersionsQuery } from "@/store/api/game-data"
import {
  useDeleteSeasonMutation,
  useGetSeasonsQuery,
  usePublishSeasonRevisionMutation
} from "@/store/api/season"
import { selectActiveOperation, setActiveOperation } from "@/store/slices/audit"
import { selectEnv, selectGame } from "@/store/slices/context"

interface Props {
  seasonId: Season["seasonId"]
}

export const SeasonCard: React.FC<Props> = ({ seasonId }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const skip = { skip: !gameId || !env }

  // DATA
  const { data: gameDataVersions, isFetching: isFetchingGameDataVersions } =
    useGetGameDataVersionsQuery({ gameId, env }, skip)
  const { season } = useGetSeasonsQuery(
    { gameId, env },
    {
      ...skip,
      selectFromResult: ({ data }) => ({
        season: data?.find((season) => season.seasonId === seasonId)
      })
    }
  )
  const {
    seasonName,
    duration,
    latestPublishedRevision,
    latestDraftRevision: revision
  } = season || {}

  const activeOperation = useAppSelector(selectActiveOperation)
  const weeks = useMemo(() => formatTimeSpanToWeeks(duration), [duration])

  // UPDATE
  const [openUpdate, setOpenUpdate] = useState<boolean>(false)

  // PUBLISH
  const [publishSeasonRevision, publishSeasonRevisionMeta] =
    usePublishSeasonRevisionMutation()
  const publishOperation: Operation = [seasonId, "publish"]
  const isPublishOperationActive = isOperationActive(
    activeOperation,
    publishOperation
  )
  const isLoadingPublish =
    isPublishOperationActive || publishSeasonRevisionMeta.isLoading

  // DELETE
  const [deleteSeason, deleteSeasonMeta] = useDeleteSeasonMutation()
  const deleteOperation: Operation = [seasonId, "delete"]
  const isDeleteOperationActive = isOperationActive(
    activeOperation,
    deleteOperation
  )
  const isLoadingDelete = isDeleteOperationActive || deleteSeasonMeta.isLoading

  const onSubmitHandler = () => {
    if (isDeleteOperationActive) deleteSeason({ seasonId, gameId })
    if (isPublishOperationActive && revision != undefined)
      publishSeasonRevision({
        seasonId,
        gameId,
        revision
      })
  }

  return (
    !!season && (
      <>
        <OperationReason
          open={isDeleteOperationActive || isPublishOperationActive}
          onSubmit={onSubmitHandler}
          autofocus
          closeable
        />

        <SeasonUpdate open={openUpdate} setOpen={setOpenUpdate} {...season} />

        <CardItem
          title={{ content: seasonName }}
          active={isLoadingDelete || isLoadingPublish}
          chips={[
            ...insertIf(latestPublishedRevision === -1, {
              content: t("state.draft"),
              color: "warning" as const
            })
          ]}
          subtitles={[{ content: seasonId, copiable: true }]}
          sections={[
            {
              title: t("label.time.duration"),
              content: t("time.duration.week", { count: weeks }),
              props: {
                minWidth: "72px"
              }
            },
            {
              title: t("item.gameData"),
              content: (
                <Button
                  size="sm"
                  aria-label="View"
                  component={Link}
                  to={`data-viewer/${seasonId}`}
                  sx={{ mt: 1 }}
                >
                  <Eye />
                </Button>
              ),
              props: {
                display: "block",
                textAlign: "center"
              }
            }
          ]}
          actions={{
            update: {
              handler: () => {
                setOpenUpdate(true)
              },
              disabled: isFetchingGameDataVersions || !gameDataVersions?.length
            },
            publish: {
              handler: () => {
                dispatch(setActiveOperation(publishOperation))
              }
            },
            delete: {
              handler: () => {
                dispatch(setActiveOperation(deleteOperation))
              }
            }
          }}
        />
      </>
    )
  )
}
