import {
  ApiError,
  ByPlayerIdQuery,
  BySpaceIdQuery,
  PlayerProfile
} from "@liveops-portal/lib"
import notify from "@/helpers/notify"
import i18next from "@/i18n"
import { api } from "@/store/api"

export const playerApi = api
  .enhanceEndpoints({
    addTagTypes: ["Player", "PlayerError"]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      findPlayerProfile: builder.query<
        PlayerProfile,
        BySpaceIdQuery & ByPlayerIdQuery
      >({
        query: ({ spaceId, id, type }) => ({
          url: `space/${spaceId}/players/profile`,
          params: { type, id }
        }),
        providesTags: (result) => {
          return result
            ? [{ type: "Player", id: result.accountId }]
            : ["PlayerError"]
        },
        onQueryStarted({ id, spaceId }, { queryFulfilled }) {
          queryFulfilled.catch(({ error: e }) => {
            const error = e as ApiError

            if (!error.errCode) {
              notify.error({
                message: i18next.t("api.findPlayerProfile.error", {
                  id,
                  spaceId
                }),
                error
              })
            }
          })
        }
      }),
      deletePlayerData: builder.mutation<
        void,
        BySpaceIdQuery & Partial<ByPlayerIdQuery>
      >({
        query: ({ spaceId, id }) => ({
          url: `space/${spaceId}/players/profile/${id}`,
          method: "DELETE"
        }),
        invalidatesTags: (result, error, { id }) => [
          { type: "Player", id: id! }
        ],
        onQueryStarted({ spaceId, id }, { queryFulfilled }) {
          queryFulfilled
            .then(() => {
              notify.success(
                i18next.t("api.deletePlayerData.success", { spaceId, id })
              )
            })
            .catch(({ error }) => {
              notify.error({
                message: i18next.t("api.deletePlayerData.error", {
                  spaceId,
                  id
                }),
                error: error as ApiError
              })
            })
        }
      })
    }),
    overrideExisting: false
  })

export const { useFindPlayerProfileQuery, useDeletePlayerDataMutation } =
  playerApi
