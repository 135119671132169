import { GameDataOption } from "@liveops-portal/lib"
import { useAppSelector } from "@/hooks/store"
import {
  useFindAllGameDataVersionsQuery,
  useFindAllGameDataQuery
} from "@/store/api/game-data"
import { selectEnv, selectGame } from "@/store/slices/context"

export const useDataType = (dataType: string | null) => {
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)

  const { data: dataVersions, isFetching } = useFindAllGameDataVersionsQuery(
    { env, gameId },
    { skip: !env || !gameId }
  )

  const result = useFindAllGameDataQuery(
    {
      env,
      gameId,
      dataVersion: dataVersions ? dataVersions[dataVersions.length - 1] : null,
      dataType
    },
    {
      skip: !env || !gameId || !dataVersions || !dataType
    }
  )

  return {
    ...result,
    data: (result.data?.map(({ guid, data, name }) => ({
      id: guid,
      label: /* c8 ignore next */ data?.name || name // Fallback to machine name if friendly name doesn't exist
    })) || []) as GameDataOption[],
    ...((result.isUninitialized || isFetching) && { isFetching: true })
  }
}
