import { mockSpace } from "../mocks"
import {
  BySpaceIdQuery,
  ConfigCategoryDeleteRequest,
  ConfigForSpaceId,
  ConfigCategoryUpdateRequest
} from "../typings"

export const mockConfig: ConfigForSpaceId = {
  general: {
    public: {
      spaceName: "xxxxxx",
      spacePrefix: "xxx_"
    },
    private: {}
  },

  "my-service": {
    public: {
      enabled: "true"
    },
    private: {}
  },

  unimatrix: {
    private: {
      gameServerBaseUrl: "gameServerBaseUrl",
      roleId: "roleId",
      gameId: "gameId",
      appId: "appId",
      appSecret: "appSecret"
    },
    public: {}
  },

  unipublish: {
    private: {
      baseUrl: "baseUrl",
      gameId: "gameId",
      signatureKey: "sign"
    },
    public: {}
  }
}

export const mockFindConfigBySpaceIdRequest: BySpaceIdQuery = {
  spaceId: mockSpace.spaceId
}

export const mockUpdateConfigCategoryRequest: ConfigCategoryUpdateRequest = {
  spaceId: mockSpace.spaceId,
  configName: "mock-service-online-dev",
  jsonData: {
    ...mockConfig,
    yetAnotherKey: { private: { randomData: "you" }, public: {} }
  }
}

export const mockDeleteConfigCategoryRequest: ConfigCategoryDeleteRequest = {
  spaceId: mockSpace.spaceId,
  configName: "mock-service-online-dev"
}
