import {
  AuditLogsResponse,
  formatJsonToString,
  Audit
} from "@liveops-portal/lib"
import { List, ListItem, Sheet, Stack, Typography } from "@mui/joy"
import { Cell, Row } from "@tanstack/react-table"
import React from "react"
import { useTranslation } from "react-i18next"
import { CodeEditor } from "@/components/code-editor/code-editor"
import { Table } from "@/components/table/table"

interface Props {
  data: AuditLogsResponse
}

export const AuditTable: React.FC<Props> = ({ data }) => {
  const { t } = useTranslation()

  const renderCodeEditor = (value: string) => {
    const decodedValue = atob(value)

    try {
      const parsedValue = JSON.parse(decodedValue)

      return (
        <CodeEditor
          sx={{
            minHeight: "400px",
            flexBasis: "50%",
            flexGrow: 1
          }}
          value={formatJsonToString(parsedValue)}
          readOnly
          name="HttpRequestBody"
        />
      )
    } catch (e) {
      return (
        <Typography flexWrap="wrap" overflow="scroll">
          {t("error.decode", { decodedValue })}
        </Typography>
      )
    }
  }

  const renderCustomSubRow = (row: Row<Audit>) => (
    <tr>
      <td
        style={{ padding: 0, whiteSpace: "wrap" }}
        colSpan={row.getVisibleCells().length + 1}
      >
        <Sheet
          variant="soft"
          sx={{
            p: 1,
            pl: 1,
            boxShadow: "inset 0 3px 6px 0 rgba(0 0 0 / 0.08)"
          }}
        >
          <Stack direction="row" gap={1}>
            <Stack gap={1} width="50%">
              <List
                sx={{
                  p: 0,
                  "--ListItem-minHeight": 0,
                  "--ListItem-paddingX": 0
                }}
              >
                {row.getAllCells().map((cell: Cell<Audit, unknown>) => {
                  switch (cell.column.columnDef.header) {
                    case "HttpRequestBody":
                      return
                    default:
                      return (
                        <ListItem
                          key={cell.column.id}
                          sx={{
                            alignItems: "flex-start",
                            flexDirection: "column"
                          }}
                        >
                          <Typography level="title-sm">
                            {cell.column.columnDef.header?.toString()}
                          </Typography>
                          <Typography
                            level="body-sm"
                            sx={{
                              wordBreak: "break-word",
                              overflowX: "auto"
                            }}
                          >
                            {row.getValue(cell.column.id)}
                          </Typography>
                        </ListItem>
                      )
                  }
                })}
              </List>
            </Stack>
            {!!row.getValue("HttpRequestBody") && (
              <Stack gap={1} width="50%">
                <Stack gap={1}>
                  <Typography level="body-md">HttpRequestBody</Typography>
                  {renderCodeEditor(row.getValue("HttpRequestBody"))}
                </Stack>
              </Stack>
            )}
          </Stack>
        </Sheet>
      </td>
    </tr>
  )

  return (
    <Stack gap={2}>
      <Table
        data={data}
        filters={[
          {
            label: t("common.username"),
            accessor: "UserName"
          },
          {
            label: t("modifier.name", { item: "item.service" }),
            accessor: "HttpRequestUrl"
          }
        ]}
        renderCustomSubRow={renderCustomSubRow}
      />
    </Stack>
  )
}
