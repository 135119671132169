import { ApiError, ResetProgressionRequest } from "@liveops-portal/lib"
import { THREADOFFATE_BATTLEPASS_ID } from "@/constants/battlepass"
import notify from "@/helpers/notify"
import i18next from "@/i18n"
import { api } from "@/store/api"

export const battlepassApi = api.injectEndpoints({
  endpoints: (builder) => ({
    resetToFProgression: builder.mutation<
      void,
      Omit<ResetProgressionRequest, "battlepassId">
    >({
      query: ({ spaceId, accountId }) => ({
        url: `battlepass/${THREADOFFATE_BATTLEPASS_ID}/progression/reset`,
        method: "POST",
        params: { accountId, spaceId }
      }),
      onQueryStarted(_, { queryFulfilled }) {
        queryFulfilled
          .then(() => {
            notify.success(i18next.t("api.resetToFProgression.success"))
          })
          .catch(({ error }) => {
            notify.error({
              message: i18next.t("api.resetToFProgression.error"),
              error: error as ApiError
            })
          })
      }
    })
  }),
  overrideExisting: false
})

export const { useResetToFProgressionMutation } = battlepassApi
