import {
  FeatureSwitchSetRequest,
  FeatureSwitchResetRequest,
  BySpaceIdQuery,
  FeatureSwitch,
  ApiError
} from "@liveops-portal/lib"
import notify from "@/helpers/notify"
import i18next from "@/i18n"
import { api } from "@/store/api"

export const featureSwitchApi = api
  .enhanceEndpoints({ addTagTypes: ["FeatureSwitch"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      findAllFeatureSwitches: builder.query<FeatureSwitch[], BySpaceIdQuery>({
        query: ({ spaceId }) => ({
          url: "/feature-switches",
          params: { spaceId }
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ switchName }) => ({
                  type: "FeatureSwitch" as const,
                  id: switchName
                })),

                { type: "FeatureSwitch", id: "ALL" }
              ]
            : [{ type: "FeatureSwitch", id: "ALL" }],
        onQueryStarted({ spaceId }, { queryFulfilled }) {
          queryFulfilled.catch(({ error }) => {
            notify.error({
              message: i18next.t("api.findAllFeatureSwitches.error", {
                spaceId
              }),
              error: error as ApiError
            })
          })
        }
      }),
      setSwitch: builder.mutation<void, FeatureSwitchSetRequest>({
        query: ({ spaceId, switchName, switchValue, qdOperationReason }) => ({
          url: `/feature-switches/${switchName}/override`,
          params: { spaceId },
          body: { switchValue },
          method: "PATCH",
          headers: {
            "QD-Operation-Reason": qdOperationReason
          }
        }),
        invalidatesTags: (result, error, { switchName }) => [
          { type: "FeatureSwitch", id: switchName }
        ],
        onQueryStarted({ switchName }, { queryFulfilled }) {
          queryFulfilled
            .then(() => {
              notify.success(
                i18next.t("api.setOverrideValue.success", { switchName })
              )
            })
            .catch(({ error }) => {
              notify.error({
                message: i18next.t("api.setOverrideValue.error", {
                  switchName
                }),
                error: error as ApiError
              })
            })
        }
      }),
      resetSwitch: builder.mutation<void, FeatureSwitchResetRequest>({
        query: ({ spaceId, switchName, qdOperationReason }) => ({
          url: `/feature-switches/${switchName}/override`,
          params: { spaceId },
          method: "DELETE",
          headers: {
            "QD-Operation-Reason": qdOperationReason
          }
        }),
        invalidatesTags: (result, error, { switchName }) => [
          { type: "FeatureSwitch", id: switchName }
        ],
        onQueryStarted({ switchName }, { queryFulfilled }) {
          queryFulfilled
            .then(() => {
              notify.success(
                i18next.t("api.deleteOverrideValue.success", { switchName })
              )
            })
            .catch(({ error }) => {
              notify.error({
                message: i18next.t("api.deleteOverrideValue.error", {
                  switchName
                }),
                error: error as ApiError
              })
            })
        }
      })
    }),
    overrideExisting: false
  })

export const {
  useFindAllFeatureSwitchesQuery,
  useSetSwitchMutation,
  useResetSwitchMutation
} = featureSwitchApi
