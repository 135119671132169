import {
  ToolboxRequest,
  isValidJson,
  formatJsonToString,
  Method,
  ServiceName
} from "@liveops-portal/lib"
import { Divider, Grid, Stack, Typography } from "@mui/joy"
import React, { useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { CodeEditor } from "@/components/code-editor/code-editor"
import { CustomErrorsList } from "@/components/custom-errors-list/custom-errors-list"
import { Form } from "@/components/form/form"
import { FormInput } from "@/components/form-input/form-input"
import { FormSelect } from "@/components/form-select/form-select"
import { Spinner } from "@/components/spinner/spinner"
import { ToolboxHistory } from "@/components/toolbox-history/toolbox-history"
import {
  getFromLocalStorageArray,
  pushToLocalStorageArray
} from "@/helpers/local-storage"
import { useAppSelector } from "@/hooks/store"
import { useUniservicesConfigStatus } from "@/hooks/useUniservicesConfigStatus"
import { useSendRequestMutation } from "@/store/api/toolbox"
import { selectSpace } from "@/store/slices/context"

interface FormData extends ToolboxRequest {}

export const ToolboxPage: React.FC = () => {
  const spaceId = useAppSelector(selectSpace)

  const { t } = useTranslation()

  const { errors, isFetching } = useUniservicesConfigStatus()

  const methods = useForm<FormData>({ mode: "all" })
  const [sendRequest, { data: response, isLoading }] = useSendRequestMutation()
  const [queryParams, setQueryParams] = useState<string>("{}")
  const [body, setBody] = useState<string>("{}")

  const onSubmit = (formData: FormData) => {
    const parsedQueryParams = JSON.parse(queryParams)
    const parsedBody = JSON.parse(body)

    const completeRequest = {
      ...formData,
      endpoint: formData.endpoint.trim(),
      queryParams: parsedQueryParams,
      body: parsedBody,
      spaceId
    }

    sendRequest(completeRequest)
    pushToLocalStorageArray("toolboxHistory", completeRequest)
  }

  const onToolboxHistoryClick = (index: number) => {
    const request = getFromLocalStorageArray(
      "toolboxHistory",
      index
    ) as ToolboxRequest

    methods.reset(request)

    setQueryParams(formatJsonToString(request.queryParams))
    setBody(formatJsonToString(request.body))
  }

  return !spaceId ? (
    <Typography>
      {t("message.uninitialized.root", {
        parent: "item.space"
      })}
    </Typography>
  ) : (
    <>
      <Spinner loading={isFetching || isLoading} />

      <Stack sx={{ gap: 2 }}>
        {Object.keys(errors).length ? (
          <CustomErrorsList errors={errors} />
        ) : (
          <Grid container spacing={2}>
            <Grid xs={8}>
              <Stack sx={{ gap: 2 }}>
                <Form
                  sx={{ gap: 2 }}
                  methods={methods}
                  disabled={!spaceId}
                  loading={isLoading}
                  onSubmit={onSubmit}
                  submitLabel={t("action.send")}
                >
                  <FormSelect
                    name="serviceName"
                    label={t("modifier.name", { item: "item.service" })}
                    defaultValue={ServiceName.UNIMATRIX_XYQ_SERVICE}
                    options={Object.values(ServiceName).map((serviceName) => ({
                      value: serviceName,
                      label: serviceName
                    }))}
                  />
                  <Stack direction="row" sx={{ gap: 2 }}>
                    <FormSelect
                      name="method"
                      label={t("label.method")}
                      defaultValue={Method.GET}
                      options={Object.values(Method).map((method) => ({
                        value: method,
                        label: method
                      }))}
                      sx={{ flexBasis: "5%" }}
                    />
                    <FormInput
                      type="text"
                      name="endpoint"
                      label={t("label.endpoint")}
                      placeholder="/endpoint"
                      rules={{
                        required: true
                      }}
                      sx={{ flexBasis: "95%" }}
                    />
                  </Stack>
                  <Stack direction="row" sx={{ gap: 2 }}>
                    <CodeEditor
                      label={t("label.queryParams")}
                      value={queryParams}
                      name="queryParams"
                      onChange={setQueryParams}
                      error={!isValidJson(queryParams)}
                      sx={{ height: "200px", flexBasis: "50%" }}
                    />
                    <CodeEditor
                      label={t("label.body")}
                      value={body}
                      name="body"
                      onChange={setBody}
                      error={!isValidJson(body)}
                      editable={true}
                      sx={{ height: "200px", flexBasis: "50%" }}
                    />
                  </Stack>
                </Form>
                <Divider />
                <CodeEditor
                  data-testid="responseCodeEditor"
                  label={t("label.response")}
                  value={JSON.stringify(response, null, 2)}
                  editable={false}
                  name="response"
                  sx={{ height: "300px" }}
                />
              </Stack>
            </Grid>
            <Grid xs={4}>
              <ToolboxHistory onClick={onToolboxHistoryClick} />
            </Grid>
          </Grid>
        )}
      </Stack>
    </>
  )
}
