import { Filter } from "@liveops-portal/lib"
import { UnfoldMore } from "@mui/icons-material"
import {
  Stack,
  Checkbox,
  Dropdown,
  Menu,
  MenuButton,
  MenuItem,
  Chip
} from "@mui/joy"
import { ChangeEventHandler } from "react"

interface Props<T> extends Filter<T> {
  selected: string[]
  onChange: ChangeEventHandler
}
export const FiltersSelect = <T,>({
  field,
  label,
  options,
  selected,
  onChange
}: Props<T>) => {
  return (
    <Dropdown>
      <MenuButton
        endDecorator={
          <Stack direction="row" gap={0.5}>
            {!!selected.length && (
              <Chip
                size="sm"
                variant="solid"
                sx={{ px: "8px" }}
                data-testid="selected-indicator"
              >
                {selected.length}
              </Chip>
            )}
            <UnfoldMore />
          </Stack>
        }
        sx={{ fontWeight: 400, fontSize: "md" }}
      >
        {label}
      </MenuButton>
      <Menu placement="bottom-start" sx={{ minWidth: 130 }}>
        {Object.keys(options).map((key) => {
          const label = options[key] as string
          const id = field as string

          return (
            <MenuItem key={key}>
              <Checkbox
                overlay
                size="sm"
                id={id}
                label={label}
                value={key}
                checked={selected?.includes(key)}
                onChange={onChange}
              />
            </MenuItem>
          )
        })}
      </Menu>
    </Dropdown>
  )
}
