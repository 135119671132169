import { IconButton } from "@mui/joy"
import { flexRender, Row } from "@tanstack/react-table"
import { NavArrowDown, NavArrowRight } from "iconoir-react"
import { ReactNode } from "react"

interface Props<TData> {
  row: Row<TData>
  renderCustomSubRow?: (row: Row<TData>) => ReactNode
}

export const TableRow = <TData,>({ row, renderCustomSubRow }: Props<TData>) => {
  return (
    <>
      <tr role="row" aria-rowindex={row.index + 1}>
        {(row.getCanExpand() || renderCustomSubRow) && (
          <td role="cell">
            <IconButton
              data-testid={`tableRowExpand-${row.id}`}
              aria-label="expand row"
              variant="plain"
              color="neutral"
              size="sm"
              onClick={() => row.toggleExpanded(!row.getIsExpanded())}
            >
              {row.getIsExpanded() ? <NavArrowDown /> : <NavArrowRight />}
            </IconButton>
          </td>
        )}
        {row.getVisibleCells().map((cell) => (
          <td key={cell.id} role="cell">
            {flexRender(cell.column.columnDef.cell, cell.getContext())}
          </td>
        ))}
      </tr>
      {row.getIsExpanded() && renderCustomSubRow?.(row)}
    </>
  )
}
