import { Action, ActionsMenu, IconType } from "@liveops-portal/lib"
import {
  MenuButton,
  IconButton,
  Menu,
  MenuItem,
  Dropdown,
  cardClasses,
  ListItemDecorator,
  IconButtonProps
} from "@mui/joy"
import {
  EditPencil,
  EraseSolid,
  Journal,
  MoreHoriz,
  TrashSolid
} from "iconoir-react"
import React from "react"
import { useTranslation } from "react-i18next"

interface Props {
  active?: boolean
  actions: ActionsMenu
}

export const CardActions: React.FC<Props> = ({ active, actions }: Props) => {
  const { t } = useTranslation()

  const keys = Object.keys(actions)
  const hasActions = !!keys.length
  const isSingleAction = keys.length === 1

  const Icons: Record<Action, IconType> = {
    update: EditPencil,
    publish: Journal,
    delete: TrashSolid,
    reset: EraseSolid
  }

  let Icon: IconType | null = isSingleAction ? Icons[keys[0]] : null

  const buttonProps: Partial<IconButtonProps> = {
    variant: "solid",
    color: "neutral",
    size: "sm"
  }

  const buttonStyle = {
    borderRadius: "50%",
    transition: "opacity 0.2s",
    transform: "scale(1) translateX(50%) translateY(-50%)",
    transformOrigin: "100% 0%",
    position: "absolute",
    cursor: "pointer",
    top: "5px",
    right: "5px",
    opacity: 0,
    visibility: "hidden",

    ...(!active && {
      [`.${cardClasses.root}:hover &, &[aria-expanded=true]`]: {
        opacity: 1,
        visibility: "visible"
      }
    })
  }

  return isSingleAction ? (
    <IconButton
      {...buttonProps}
      color={["delete", "reset"].includes(keys[0]) ? "danger" : "neutral"}
      sx={buttonStyle}
      onClick={actions[keys[0]]?.handler}
      disabled={actions[keys[0]]?.disabled}
      aria-label={actions[keys[0]]?.label || t(`action.${keys[0]}`)}
      title={actions[keys[0]]?.label || t(`action.${keys[0]}`)}
    >
      {Icon && <Icon />}
    </IconButton>
  ) : (
    hasActions && (
      <Dropdown>
        <MenuButton
          slots={{ root: IconButton }}
          slotProps={{ root: buttonProps }}
          sx={buttonStyle}
          aria-label={t("label.actions")}
          title={t("label.actions")}
        >
          <MoreHoriz />
        </MenuButton>
        <Menu
          disablePortal
          placement="bottom-end"
          size="sm"
          variant="outlined"
          sx={{
            "--ListDivider-gap": "0",
            "--ListItemDecorator-size": "24px"
          }}
        >
          {Object.keys(actions).map((action) => {
            Icon = Icons[action]

            return (
              <MenuItem
                key={action}
                color={
                  ["delete", "reset"].includes(action) ? "danger" : "neutral"
                }
                variant={
                  ["delete", "reset"].includes(action) ? "soft" : "plain"
                }
                onClick={actions[action]?.handler}
                disabled={actions[action]?.disabled}
                aria-label={actions[action]?.label || t(`action.${action}`)}
                title={actions[action]?.label || t(`action.${action}`)}
              >
                <ListItemDecorator>
                  <Icon />
                </ListItemDecorator>
                {t(`action.${action}`)}
              </MenuItem>
            )
          })}
        </Menu>
      </Dropdown>
    )
  )
}
