import {
  Battlepass,
  BattlepassXpRequiredTypes,
  Operation,
  SeasonComponentItem
} from "@liveops-portal/lib"

import {
  Table,
  Sheet,
  Typography,
  IconButton,
  typographyClasses
} from "@mui/joy"
import { Collapse } from "@mui/material"
import dayjs from "dayjs"
import { NavArrowDown } from "iconoir-react"
import React, { Fragment, useState } from "react"
import { useTranslation } from "react-i18next"
import { BattlepassUpdate } from "@/components/battlepass-update/battlepass-update"
import { CardItem } from "@/components/card-item/card-item"
import { OperationReason } from "@/components/operation-reason/operation-reason"
import { isOperationActive } from "@/helpers/operation-reason"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import {
  useDeleteSeasonComponentItemMutation,
  useGetSeasonComponentItemsQuery
} from "@/store/api/season"
import { selectActiveOperation, setActiveOperation } from "@/store/slices/audit"
import { selectEnv, selectGame, selectSeason } from "@/store/slices/context"

interface Props {
  itemId: SeasonComponentItem["itemId"]
}

export const BattlepassCard: React.FC<Props> = ({ itemId }) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()

  const activeOperation = useAppSelector(selectActiveOperation)
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const seasonId = useAppSelector(selectSeason)

  // DATA
  const [expandTracks, setExpandTracks] = useState<boolean>(false)
  const { item } = useGetSeasonComponentItemsQuery(
    { gameId, seasonId, env, componentType: "battlepass" },
    {
      skip: !seasonId || !gameId || !env,
      selectFromResult: ({ data }) => ({
        item: data?.find((item) => item.itemId === itemId) as
          | SeasonComponentItem<Battlepass>
          | undefined
      })
    }
  )
  const { displayName, duration, xpRequired, tracks, lastModified } = item || {}

  // UPDATE
  const [openUpdate, setOpenUpdate] = useState<boolean>(false)

  // DELETE
  const [deleteBattlepass, deleteBattlepassMeta] =
    useDeleteSeasonComponentItemMutation()
  const deleteOperation: Operation = [itemId, "delete"]
  const isDeleteOperationActive = isOperationActive(
    activeOperation,
    deleteOperation
  )
  const isLoadingDelete =
    isDeleteOperationActive || deleteBattlepassMeta.isLoading

  const onSubmitHandler = () => {
    if (isDeleteOperationActive)
      deleteBattlepass({
        seasonId,
        gameId,
        itemId,
        componentType: "battlepass"
      })
  }

  return (
    item && (
      <>
        <OperationReason
          open={isDeleteOperationActive}
          onSubmit={onSubmitHandler}
          autofocus
          closeable
        />

        <BattlepassUpdate open={openUpdate} setOpen={setOpenUpdate} {...item} />

        <CardItem
          title={{ content: displayName }}
          active={isLoadingDelete}
          chips={[
            {
              content: t(`label.season.durationTypes.${duration?.type}`)
            }
          ]}
          subtitles={[
            { content: itemId, copiable: true },
            {
              label: t("label.time.lastModified"),
              content: dayjs(lastModified).utc().format("YYYY-MM-DD HH:mm:ss")
            },
            {
              content: (
                <>
                  <Typography
                    fontWeight="md"
                    fontSize="sm"
                    startDecorator={
                      <IconButton
                        size="sm"
                        variant="plain"
                        aria-controls={`${itemId}.tracks`}
                        aria-expanded={expandTracks}
                        aria-label={
                          expandTracks
                            ? t("action.item", {
                                action: "action.collapse",
                                item: "item.tracks"
                              })
                            : t("action.item", {
                                action: "action.expand",
                                item: "item.tracks"
                              })
                        }
                        sx={{
                          color: "text.tertiary",
                          [`&.${typographyClasses.root}:hover`]: {
                            bgcolor: "transparent"
                          }
                        }}
                        onClick={() => {
                          setExpandTracks(!expandTracks)
                        }}
                      >
                        <NavArrowDown
                          style={{
                            transform: expandTracks ? "none" : "rotate(-90deg)",
                            transition: "all 0.2s"
                          }}
                        />
                      </IconButton>
                    }
                  >
                    {t("item.tracks")}
                  </Typography>

                  <Collapse in={expandTracks} timeout={200}>
                    <Sheet sx={{ maxHeight: 200, overflow: "auto" }}>
                      <Table
                        noWrap
                        stickyHeader
                        id={`${itemId}.tracks`}
                        data-testid={`${itemId}.tracks`}
                        aria-expanded={expandTracks}
                        sx={{
                          tableLayout: "auto",
                          "--TableCell-height": "auto",
                          "& thead tr:first-of-type th": {
                            verticalAlign: "bottom"
                          },
                          "& thead th": {
                            verticalAlign: "middle"
                          }
                        }}
                      >
                        <thead>
                          <tr>
                            <th style={{ width: 32 }}>{t("item.level")}</th>
                            {tracks?.map(
                              ({ displayName: trackName }, trackIdx) => (
                                <th key={`listHeader[${trackIdx}]`}>
                                  {trackName} {t("item.track")}
                                </th>
                              )
                            )}
                          </tr>
                        </thead>
                        <tbody>
                          {tracks?.[0]?.levels?.map(({ index: levelIdx }) => (
                            <tr key={`listRow[${levelIdx}]`}>
                              <th scope="row" style={{ textAlign: "right" }}>
                                {levelIdx + 1}
                              </th>
                              {tracks.map(({ levels }, trackIdx) => {
                                const { rewards } = levels[levelIdx]
                                return (
                                  <td
                                    key={`listRow[${levelIdx}].track[${trackIdx}].rewards`}
                                    style={{ verticalAlign: "top" }}
                                  >
                                    <Typography>
                                      {rewards.map(
                                        ({ rewardId }, rewardIdx) => (
                                          <Fragment
                                            key={`listRow[${levelIdx}].track[${trackIdx}].rewards[${rewardIdx}]`}
                                          >
                                            {rewardId}
                                            {rewardIdx < rewards.length - 1 &&
                                              ", "}
                                          </Fragment>
                                        )
                                      )}
                                    </Typography>
                                  </td>
                                )
                              })}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </Sheet>
                  </Collapse>
                </>
              )
            }
          ]}
          sections={[
            {
              title: t("label.time.duration"),
              content: t("time.duration.day", { count: duration?.length })
            },
            {
              title: t("label.season.xpPerLevel"),
              content:
                xpRequired?.type === BattlepassXpRequiredTypes.CUSTOM
                  ? t("label.season.xpRequiredTypes.custom")
                  : xpRequired?.linearValue
            },
            {
              title: t("item.levels"),
              content: tracks?.[0].levels.length
            }
          ]}
          actions={{
            update: {
              handler: () => {
                setOpenUpdate(true)
              }
            },
            delete: {
              handler: () => {
                dispatch(setActiveOperation(deleteOperation))
              }
            }
          }}
        />
      </>
    )
  )
}
