import { Audit, formatJsonToString } from "@liveops-portal/lib"
import {
  Badge,
  IconButton,
  List,
  ListItem,
  Sheet,
  Stack,
  Typography,
  Table,
  ColorPaletteProp,
  Chip,
  Tooltip,
  FormLabel,
  FormControl,
  Select,
  Option
} from "@mui/joy"
import { capitalize } from "@mui/material"
import {
  Row,
  Cell,
  ColumnFiltersState,
  ExpandedState,
  flexRender,
  getCoreRowModel,
  getExpandedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  useReactTable,
  VisibilityState,
  FilterFn
} from "@tanstack/react-table"
import dayjs from "dayjs"
import { NavArrowDown, WarningTriangleSolid } from "iconoir-react"
import React, { Fragment, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { CodeEditor } from "@/components/code-editor/code-editor"
import { Filters } from "@/components/filters/filters"
import { Spinner } from "@/components/spinner/spinner"
import { TablePagination } from "@/components/table-pagination/table-pagination"
import { useGetAuditsQuery } from "@/store/api/audit"

export const AuditPage: React.FC = () => {
  const { t } = useTranslation()
  const [duration, setDuration] = useState<number>(15)
  const [expanded, setExpanded] = useState<ExpandedState>({})
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({
    TimeGenerated: true,
    UserName: true,
    OperationReason: true,
    HttpRequestUrl: false,
    HttpRequestMethod: true,
    HttpRequestHost: false,
    HttpRequestPath: true,
    HttpRequestUrlParams: false,
    HttpRequestBody: false,
    HttpResponseStatusCode: true,
    ProjectName: true,
    Environment: true
  })
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25
  })

  const {
    data: audits,
    isFetching: isFetchingAudits,
    refetch
  } = useGetAuditsQuery({
    duration
  })

  const userFilter = useMemo(
    () =>
      [...new Set(audits?.map(({ UserName }) => UserName))]
        .sort((a, b) => a.localeCompare(b))
        .map((value) => ({ label: value, value: value })),
    [audits]
  )

  const serviceFilter = useMemo(
    () =>
      [...new Set(audits?.map(({ ProjectName }) => ProjectName))]
        .sort((a, b) => a.localeCompare(b))
        .map((value) => ({ label: value, value: value })),
    [audits]
  )

  const filters = useMemo(
    () => [
      {
        accessor: "UserName",
        label: t("common.username"),
        options: userFilter
      },
      {
        accessor: "ProjectName",
        label: t("modifier.name", { item: "item.service" }),
        options: serviceFilter
      }
    ],
    [t, userFilter, serviceFilter]
  )

  const getColumnWidth = (id: string) => {
    switch (id) {
      case "HttpResponseStatusCode":
        return 60
      case "Environment":
        return 115
      case "TimeGenerated":
        return 180
      case "HttpRequestMethod":
        return 70
      default:
        return "auto"
    }
  }

  const formatCell = (id: string, value: string | number) => {
    const validStatuses = [200, 201, 204]

    switch (id) {
      case "HttpResponseStatusCode":
        return (
          <Tooltip arrow describeChild title={value} size="sm">
            <Badge
              color={
                typeof value === "number" && validStatuses.includes(value)
                  ? "success"
                  : "danger"
              }
            />
          </Tooltip>
        )
      case "Environment":
        return <Chip color={value as ColorPaletteProp}>{value}</Chip>
      case "TimeGenerated":
        return dayjs(value).format("YYYY-MM-DD HH:mm:ss z")
      case "ProjectName":
        return value ? value : t("common.notApplicable")
      case "HttpRequestMethod":
        return value ? <Chip>{value}</Chip> : t("common.notApplicable")
      case "HttpRequestPath":
        return value ? (
          <Typography noWrap fontFamily="monospace" component="pre">
            {String(value).replace("/api/", "")}
          </Typography>
        ) : (
          t("common.notApplicable")
        )
      default:
        return value
    }
  }

  const filterFn: FilterFn<Audit> = ({ getValue }, id, filterValue) =>
    filterValue.some((f: string) => f === getValue(id))
  filterFn.autoRemove = (val) => !val.length

  const {
    getAllColumns,
    getColumn,
    getHeaderGroups,
    getRowModel,
    getRowCount,
    getState,
    setPageSize,
    setPageIndex,
    getVisibleFlatColumns
  } = useReactTable<Audit>({
    data: audits || [],
    columns: audits?.length
      ? Object.keys(audits[0]).map((key) => ({
          id: key,
          accessorKey: key,
          header: t(`label.audit.${key}`),
          cell: (info) => formatCell(key, info.getValue()),
          filterFn
        }))
      : [],
    initialState: {
      columnOrder: [
        "HttpResponseStatusCode",
        "Environment",
        "TimeGenerated",
        "ProjectName",
        "HttpRequestMethod",
        "HttpRequestUrl",
        "HttpRequestHost",
        "HttpRequestPath",
        "HttpRequestUrlParams",
        "HttpRequestBody",
        "UserName",
        "OperationReason"
      ]
    },
    enableColumnResizing: false,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    getRowCanExpand: () => true,
    state: {
      columnVisibility,
      columnFilters,
      expanded,
      pagination
    },
    onColumnFiltersChange: setColumnFilters,
    onExpandedChange: setExpanded,
    onPaginationChange: setPagination,
    onColumnVisibilityChange: setColumnVisibility
  })

  const renderCodeEditor = (value: string) => {
    const decodedValue = atob(value)
    try {
      const parsedValue = JSON.parse(decodedValue)

      return (
        <CodeEditor
          sx={{ flexGrow: 1 }}
          label={t("label.audit.HttpRequestBody")}
          value={formatJsonToString(parsedValue)}
          readOnly
          name="HttpRequestBody"
        />
      )
    } catch (_) {
      return (
        <>
          <Typography
            color="warning"
            level="body-sm"
            startDecorator={<WarningTriangleSolid />}
          >
            {t("error.decode")}
          </Typography>
          <Typography level="body-sm" sx={{ mt: 1, mb: 0.5 }}>
            {t("label.originalString")}:
          </Typography>
          <Typography
            component="pre"
            level="body-xs"
            textColor="text.primary"
            fontFamily="monospace"
          >
            {decodedValue}
          </Typography>
        </>
      )
    }
  }

  const renderSubRow = (row: Row<Audit>) => {
    const { getVisibleCells, getAllCells, getValue } = row
    const hasBody = !!getValue("HttpRequestBody")

    return (
      <tr>
        <td style={{ padding: 0 }} colSpan={getVisibleCells().length + 1}>
          <Sheet variant="soft" sx={{ px: 2, py: 1 }}>
            <Stack sx={{ gap: 1, flexDirection: "row" }}>
              <Stack sx={{ width: hasBody ? "50%" : "100%" }}>
                <List
                  sx={{
                    p: 0,
                    "--ListItem-minHeight": 0
                  }}
                >
                  {getAllCells().map((cell: Cell<Audit, unknown>) => {
                    const { id } = cell.column.columnDef
                    if (id !== "HttpRequestBody") {
                      return (
                        !!getValue(cell.column.id) && (
                          <ListItem
                            key={cell.column.id}
                            sx={{ px: 0, alignItems: "flex-start" }}
                          >
                            <Typography level="title-sm">
                              {cell.column.columnDef.header?.toString()}
                            </Typography>
                            <Typography
                              component="pre"
                              level="body-sm"
                              sx={{
                                whiteSpace: "pre-wrap",
                                fontFamily: "monospace"
                              }}
                            >
                              {row.getValue(cell.column.id)}
                            </Typography>
                          </ListItem>
                        )
                      )
                    }
                  })}
                </List>
              </Stack>
              {hasBody && (
                <Stack sx={{ width: "50%", mb: 1 }}>
                  {renderCodeEditor(getValue("HttpRequestBody"))}
                </Stack>
              )}
            </Stack>
          </Sheet>
        </td>
      </tr>
    )
  }

  const onFilterChangeHandler = (accessor: string, value: string) => {
    const column = getColumn(accessor)
    if (column) {
      const { getIsFiltered, getFilterValue, setFilterValue } = column
      const prevFilter = getFilterValue() as string[]

      if (!getIsFiltered()) {
        setFilterValue([value])
        return
      }

      const nextFilter = prevFilter?.includes(value)
        ? prevFilter.filter((f) => f !== value)
        : [...prevFilter, value]

      setFilterValue(nextFilter)
    }
  }

  const onResetHandler = () =>
    getAllColumns().forEach(({ setFilterValue }) => setFilterValue([]))

  return (
    <>
      <Spinner loading={isFetchingAudits} />

      <Stack sx={{ gap: 2, flexGrow: 1 }}>
        {!!audits?.length && (
          <Filters
            filters={filters}
            onFilterChange={onFilterChangeHandler}
            onReset={onResetHandler}
          />
        )}

        <Sheet
          variant="outlined"
          sx={{
            borderRadius: "sm",
            overflow: "hidden"
          }}
        >
          <Sheet
            sx={(theme) => ({
              "--TableHeader-height": "40px",
              "--TableFooter-height": "46px",
              "--TableBody-shadow": theme.vars.palette.background.level1,
              ...theme.applyStyles("light", {
                "--TableBody-shadow": theme.vars.palette.background.level2
              }),
              maxHeight: "calc(100vh - 191px)",
              overflowY: "auto",
              background: `linear-gradient(${theme.vars.palette.background.surface} 33%, rgba(0,0,0, 0)),
                  linear-gradient(rgba(0,0,0, 0), ${theme.vars.palette.background.surface} 66%) 0 100%,
                  radial-gradient(farthest-side at 50% 0, var(--TableBody-shadow), rgba(0,0,0,0)),
                  radial-gradient(farthest-side at 50% 100%, var(--TableBody-shadow), rgba(0,0,0,0)) 0 100%`,
              backgroundColor: "background.surface",
              backgroundRepeat: "no-repeat",
              backgroundAttachment: "local, local, scroll, scroll",
              backgroundSize: "100% 45px, 100% 45px, 100% 15px, 100% 15px",
              backgroundPosition:
                "0 var(--TableHeader-height), 0 calc(100% - var(--TableFooter-height)), 0 var(--TableHeader-height), 0 calc(100% - var(--TableFooter-height))"
            })}
          >
            <Table
              noWrap
              hoverRow
              stickyHeader
              stickyFooter
              aria-rowcount={getRowCount()}
            >
              <thead>
                {getHeaderGroups().map(({ id, headers }) => (
                  <tr key={id}>
                    <th style={{ width: 40 }}></th>
                    {headers.map(({ id, column, getContext }) => (
                      <th
                        key={id}
                        style={{
                          width: getColumnWidth(id),
                          textAlign:
                            id.includes("HttpResponseStatusCode") ||
                            id.includes("HttpResponseMethod") ||
                            id.includes("Environment")
                              ? "center"
                              : "left"
                        }}
                      >
                        {flexRender(column.columnDef.header, getContext())}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>

              <tbody>
                {audits?.length ? (
                  <>
                    {getRowModel().rows.map((row) => {
                      const {
                        id,
                        index,
                        getCanExpand,
                        toggleExpanded,
                        getIsExpanded,
                        getVisibleCells
                      } = row
                      return (
                        <Fragment key={id}>
                          <tr
                            aria-rowindex={index + 1}
                            onClick={() => toggleExpanded(!getIsExpanded())}
                            style={{ cursor: "pointer" }}
                          >
                            {getCanExpand() && (
                              <td>
                                <IconButton
                                  aria-label={t("action.expand")}
                                  variant="plain"
                                  color="neutral"
                                  size="sm"
                                  onClick={() =>
                                    toggleExpanded(!getIsExpanded())
                                  }
                                  sx={{
                                    transition: "all 0.2s",
                                    transform: getIsExpanded()
                                      ? "none"
                                      : "rotate(-90deg)"
                                  }}
                                >
                                  <NavArrowDown />
                                </IconButton>
                              </td>
                            )}
                            {getVisibleCells().map(
                              ({ id, column: { columnDef }, getContext }) => {
                                return (
                                  <td
                                    key={id}
                                    style={{
                                      textAlign:
                                        id.includes("HttpResponseStatusCode") ||
                                        id.includes("HttpRequestMethod") ||
                                        id.includes("Environment")
                                          ? "center"
                                          : "left"
                                    }}
                                  >
                                    {flexRender(columnDef.cell, getContext())}
                                  </td>
                                )
                              }
                            )}
                          </tr>
                          {getIsExpanded() && renderSubRow(row)}
                        </Fragment>
                      )
                    })}
                    {!getRowCount() && (
                      <tr aria-rowindex={1}>
                        <td
                          colSpan={getVisibleFlatColumns().length + 1}
                          style={{ textAlign: "center" }}
                        >
                          {t("message.noItems.selectedCriteria", {
                            item: "item.logs"
                          })}
                        </td>
                      </tr>
                    )}
                  </>
                ) : (
                  <tr aria-rowindex={1}>
                    <td
                      colSpan={getVisibleFlatColumns().length + 1}
                      style={{ textAlign: "center" }}
                    >
                      <Typography>
                        {t("message.noItems.root", { item: "item.logs" })}
                      </Typography>
                    </td>
                  </tr>
                )}
              </tbody>

              <tfoot>
                <tr>
                  <td colSpan={getVisibleFlatColumns().length + 1}>
                    <Stack
                      sx={{
                        gap: 2,
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-end"
                      }}
                    >
                      <FormControl orientation="horizontal" size="sm">
                        <FormLabel>
                          {capitalize(
                            t("label.audit.range", {
                              unit: "time.unit.day_other"
                            })
                          )}
                          :
                        </FormLabel>
                        <Select
                          size="sm"
                          name="duration"
                          defaultValue={15}
                          onChange={(_, value) => {
                            value && setDuration(value)
                            refetch()
                          }}
                          sx={{ ml: 1 }}
                        >
                          {[15, 30, 60, 90].map((value) => (
                            <Option key={value} value={value}>
                              {value.toString()}
                            </Option>
                          ))}
                        </Select>
                      </FormControl>
                      <TablePagination
                        count={getRowCount()}
                        page={getState().pagination.pageIndex}
                        onPageChange={setPageIndex}
                        pageSizeOptions={[25, 50, 100]}
                        onPageSizeChange={setPageSize}
                        pageSize={getState().pagination.pageSize}
                      />
                    </Stack>
                  </td>
                </tr>
              </tfoot>
            </Table>
          </Sheet>
        </Sheet>
      </Stack>
    </>
  )
}
