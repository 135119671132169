import { HeaderGroup, flexRender } from "@tanstack/react-table"

interface Props<TData> {
  headerGroups: HeaderGroup<TData>[]
  hasCollapsibleColumn?: boolean
}

export const TableHeader = <TData,>({
  headerGroups,
  hasCollapsibleColumn
}: Props<TData>) => {
  return (
    <thead>
      {headerGroups.map(({ id, headers }) => (
        <tr role="row" key={id}>
          {hasCollapsibleColumn && <th style={{ width: "50px" }}></th>}
          {headers.map(({ id, isPlaceholder, column, getContext }) => (
            <th key={id} role="columnheader">
              {!isPlaceholder && (
                <div>{flexRender(column.columnDef.header, getContext())}</div>
              )}
            </th>
          ))}
        </tr>
      ))}
    </thead>
  )
}
