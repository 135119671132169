import {
  AllApplicationsQuery,
  ApiError,
  Application,
  CreateApplicationMutation
} from "@liveops-portal/lib"
import notify from "@/helpers/notify"
import i18next from "@/i18n"
import { api } from "@/store/api"

export const applicationApi = api
  .enhanceEndpoints({
    addTagTypes: ["Application"]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      getAllApplicationsByGameId: builder.query<
        Application[],
        AllApplicationsQuery
      >({
        query: ({ gameId, includeMarkedForDeletion }) => ({
          url: `game/${gameId}/applications`,
          params: { includeMarkedForDeletion }
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ appId }) => ({
                  type: "Application" as const,
                  id: appId
                })),

                { type: "Application", id: "ALL" }
              ]
            : [{ type: "Application", id: "ALL" }],
        onQueryStarted({ gameId }, { queryFulfilled }) {
          queryFulfilled.catch(({ error }) => {
            notify.error({
              message: i18next.t("api.getAllApplicationsByGameId.error", {
                gameId
              }),
              error: error as ApiError
            })
          })
        }
      }),
      createApplication: builder.mutation<void, CreateApplicationMutation>({
        query: ({ gameId, defaultSpaceId, name, qdOperationReason }) => {
          return {
            url: `game/${gameId}/applications`,
            method: "POST",
            body: {
              defaultSpaceId,
              name
            },
            headers: {
              "QD-Operation-Reason": qdOperationReason
            }
          }
        },
        invalidatesTags: [{ type: "Application", id: "ALL" }],
        onQueryStarted(_, { queryFulfilled }) {
          queryFulfilled
            .then(() => {
              notify.success(i18next.t("api.createApplication.success"))
            })
            .catch(({ error }) => {
              notify.error({
                message: i18next.t("api.createApplication.error"),
                error: error as ApiError
              })
            })
        }
      })
    }),
    overrideExisting: false
  })

export const {
  useGetAllApplicationsByGameIdQuery,
  useCreateApplicationMutation
} = applicationApi
