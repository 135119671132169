import { ApiError, ByEnvQuery, sortByKey, Space } from "@liveops-portal/lib"
import notify from "@/helpers/notify"
import i18next from "@/i18n"
import { api } from "@/store/api"

export const spaceApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllSpaces: builder.query<Space[], ByEnvQuery>({
      query: () => ({
        url: "spaces"
      }),
      transformResponse: (spaces: Space[]) => sortByKey(spaces, "spaceName"),
      onQueryStarted(_, { queryFulfilled }) {
        queryFulfilled.catch(({ error }) => {
          notify.error({
            message: i18next.t("api.getAllSpaces.error"),
            error: error as ApiError
          })
        })
      }
    })
  }),
  overrideExisting: false
})

export const { useGetAllSpacesQuery } = spaceApi
