import { HealthcheckCreateRequest } from "@liveops-portal/lib"
import { Button, Stack } from "@mui/joy"
import { isURL } from "class-validator"
import { Plus } from "iconoir-react"
import React, { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "@/components/form/form"
import { FormInput } from "@/components/form-input/form-input"
import { FormSwitch } from "@/components/form-switch/form-switch"
import { Modal } from "@/components/modal/modal"
import { useCreateHealthcheckMutation } from "@/store/api/healthcheck"

type FormData = HealthcheckCreateRequest

export const HealthcheckCreate: React.FC = () => {
  const { t } = useTranslation()
  const methods = useForm<FormData>({
    mode: "all",
    defaultValues: {
      url: "https://",
      subscriptionKey: "",
      msTeamsNotifierWebhookId: "core",
      enabled: false
    }
  })
  const [createHealthcheck, mutation] = useCreateHealthcheckMutation()
  const { isSuccess, isLoading } = mutation
  const [open, setOpen] = useState<boolean>(false)

  const onCloseHandler = useCallback(() => {
    methods.reset()
    mutation.reset()
    setOpen(false)
  }, [methods, mutation])

  const onSubmitHandler = (data: FormData) => createHealthcheck(data)

  useEffect(() => {
    if (isSuccess) {
      onCloseHandler()
    }
  }, [isSuccess, onCloseHandler])

  const action = "action.register"
  const item = "item.healthcheck"

  return (
    <>
      <Button
        variant="outlined"
        loadingPosition="start"
        onClick={() => setOpen(true)}
        startDecorator={<Plus />}
      >
        {t(action)}
      </Button>

      <Modal
        open={open}
        onClose={onCloseHandler}
        title={t("action.item", {
          action,
          item
        })}
      >
        <Form
          auditable
          methods={methods}
          loading={isLoading}
          submitLabel={t(action)}
          onSubmit={onSubmitHandler}
          gap={2}
          direction="column"
          fieldsToTrim={[
            "serviceName",
            "url",
            "subscriptionKey",
            "msTeamsNotifierWebhookId"
          ]}
        >
          <Stack direction="row" gap={1}>
            <FormInput
              type="text"
              name="serviceName"
              label={t("modifier.name", { item: "item.service" })}
              rules={{
                required: true,
                minLength: 3
              }}
              sx={{ minWidth: 300 }}
            />

            <FormInput
              type="url"
              name="url"
              label={t("label.healthcheck.serviceUrl")}
              rules={{
                required: true,
                validate: (value) =>
                  isURL(value) ||
                  t("error.url", { label: "label.healthcheck.serviceUrl" })
              }}
              sx={{ flexGrow: 1 }}
            />

            <FormInput
              type="text"
              name="subscriptionKey"
              label={t("label.healthcheck.subscriptionKey")}
              rules={{ required: false }}
            />
          </Stack>

          <Stack direction="row" gap={1}>
            <FormInput
              type="text"
              name="msTeamsNotifierWebhookId"
              label={t("label.healthcheck.msTeamsNotifierWebhookId")}
              rules={{
                required: true
              }}
              sx={{ flexGrow: 1 }}
            />
            <FormSwitch name="enabled" label={t("state.enabled")} />
          </Stack>
        </Form>
      </Modal>
    </>
  )
}
