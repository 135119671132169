import { Card, CardContent, List, ListItem, Stack, Typography } from "@mui/joy"
import { Grid2 as Grid } from "@mui/material"
import React from "react"
import { useTranslation } from "react-i18next"
import { CopyToClipboard } from "@/components/copy-to-clipboard/copy-to-clipboard"
import { useAppSelector } from "@/hooks/store"
import { useFindPlayerProfileQuery } from "@/store/api/player"
import { selectSpace, selectPlayer } from "@/store/slices/context"

export const PlayerIds: React.FC = () => {
  const { t } = useTranslation()
  const spaceId = useAppSelector(selectSpace)
  const { type, id } = useAppSelector(selectPlayer)
  const { currentData: playerProfile } = useFindPlayerProfileQuery(
    { spaceId, id, type },
    { skip: !spaceId || !type || !id }
  )

  return (
    !!playerProfile && (
      <Grid size={{ xs: 12, lg: 4 }} component={Stack} sx={{ gap: 2 }}>
        <Typography level="h3">{t("label.player.playerIds")}</Typography>
        <Card variant="outlined" sx={{ flexGrow: 1 }}>
          <CardContent sx={{ gap: 2 }}>
            <List
              sx={{
                p: 0,
                "--ListItem-minHeight": 0,
                "--ListItem-paddingX": 0
              }}
            >
              <ListItem>
                <Typography level="title-sm">
                  {t("label.player.type.unimatrix")}:
                </Typography>
                <Typography level="body-sm">
                  <CopyToClipboard>{playerProfile.accountId}</CopyToClipboard>
                </Typography>
              </ListItem>
              <ListItem>
                <Typography level="title-sm">
                  {t("label.player.type.playfab")}:
                </Typography>
                <Typography
                  level="body-sm"
                  fontStyle={
                    playerProfile.PlayfabProfileId ? "normal" : "italic"
                  }
                >
                  {playerProfile.PlayfabProfileId ? (
                    <CopyToClipboard>
                      {playerProfile.PlayfabProfileId}
                    </CopyToClipboard>
                  ) : (
                    t("common.none")
                  )}
                </Typography>
              </ListItem>
            </List>
          </CardContent>
        </Card>
      </Grid>
    )
  )
}
