import { GameData, GameDataTypes, GameDataVersion } from "../typings"
import { mockGame } from "./game.mocks"

export const mockGameDataTypes: GameDataTypes = ["spells"]

export const mockGameData: GameData[] = [
  {
    name: "spells1",
    someOtherProperty: {}
  },
  {
    name: "spells2",
    someOtherProperty: {}
  }
]

export const mockGameDataVersion: GameDataVersion = {
  gameId: mockGame.gameId,
  dataVersion: 0,
  state: "READY_FOR_USE",
  preserved: true,
  lastUpdate: "2000-10-31T01:30:00.000000Z"
}
