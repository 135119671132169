import { Stack, FormControl, useColorScheme, Chip } from "@mui/joy"
import { SxProps } from "@mui/system"
import { nanoid } from "@reduxjs/toolkit"
import { LanguageName, loadLanguage } from "@uiw/codemirror-extensions-langs"
import CodeMirror, { ReactCodeMirrorProps } from "@uiw/react-codemirror"
import React from "react"
import { FormLabel } from "@/components/form-label/form-label"
import { codeEditorDark, codeEditorLight } from "@/styles"

interface Props extends ReactCodeMirrorProps {
  value: string
  name: string
  error?: boolean
  label?: string
  language?: LanguageName
  sx?: SxProps
}
/**
 * CodeEditor component.
 * Styles a code editor textarea using JoyUI
 */
export const CodeEditor: React.FC<Props> = ({
  value,
  name,
  label,
  error,
  language = "json",
  onChange,
  sx,
  ...props
}: Props) => {
  const { mode } = useColorScheme()

  const id = `code-editor-${nanoid()}`

  return (
    <Stack
      component={FormControl}
      error={error}
      sx={sx}
      data-testid="codeEditor"
    >
      {label && <FormLabel id={id}>{label}</FormLabel>}
      <Stack
        flexGrow={1}
        position="relative"
        borderRadius="sm"
        border={1}
        borderColor={error ? "danger.outlinedBorder" : "neutral.outlinedBorder"}
        boxShadow="xs"
        overflow="hidden"
        sx={{
          "& .cm-theme, & .cm-editor, & .cm-scroller": {
            borderRadius: "inherit"
          }
        }}
      >
        <CodeMirror
          value={value}
          extensions={[loadLanguage(language)!]}
          aria-labelledby={id}
          theme={mode === "dark" ? codeEditorDark : codeEditorLight}
          onChange={onChange}
          height="100%"
          style={{ position: "absolute", inset: 0 }}
          {...props}
        />

        <input type="hidden" name={name} defaultValue={value} />

        {error && (
          <Stack
            direction="row"
            justifyContent="center"
            position="absolute"
            bottom={0}
            left={0}
            right={0}
            mb={2}
          >
            <Chip color="danger" variant="solid">
              {language} is not valid
            </Chip>
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}
