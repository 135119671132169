import {
  Autocomplete,
  AutocompleteOption,
  createFilterOptions,
  ListItemContent,
  Typography
} from "@mui/joy"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { generatePath, useNavigate, useParams } from "react-router-dom"
import { EntityChip } from "@/components/entity-chip/entity-chip"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { useRoutePath } from "@/hooks/useRoutePath"
import { useFindAllSpacesQuery } from "@/store/api/space"
import {
  selectEnv,
  selectGame,
  selectSeason,
  selectSpace,
  setSpaceId
} from "@/store/slices/context"

export const SpaceSelector: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const currentPath = useRoutePath()
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const spaceId = useAppSelector(selectSpace)
  const seasonId = useAppSelector(selectSeason)
  const { spaceId: spaceIdParam } = useParams()
  const { data: spaces, isFetching } = useFindAllSpacesQuery(
    { env },
    {
      skip: !env || !gameId,
      selectFromResult: ({ data, isFetching }) => ({
        data: data?.filter((space) => space.gameId === gameId),
        isFetching
      })
    }
  )

  useEffect(() => {
    if (currentPath?.includes("spaceId") && spaceIdParam !== spaceId) {
      dispatch(setSpaceId(spaceIdParam || null))
    }
  }, [dispatch, currentPath, spaceId, spaceIdParam])

  return (
    currentPath?.includes("spaceId") &&
    !!spaces && (
      <Autocomplete
        autoComplete
        aria-label={t("label.spaceSelector")}
        placeholder={t("placeholder.space")}
        disabled={isFetching}
        sx={{
          width: 260
        }}
        value={spaces.find((s) => s.spaceId === spaceId) || null}
        startDecorator={spaceId ? <EntityChip type="space" /> : null}
        onChange={(_, newSpace) => {
          if (!newSpace) {
            if (spaceId) dispatch(setSpaceId(null))
            currentPath &&
              navigate(
                generatePath(currentPath, {
                  env,
                  gameId,
                  spaceId: null,
                  seasonId
                })
              )
            return
          }

          if (newSpace.spaceId !== spaceId) {
            dispatch(setSpaceId(newSpace.spaceId))
            currentPath &&
              navigate(
                generatePath(currentPath, {
                  env,
                  gameId,
                  spaceId: newSpace.spaceId,
                  seasonId
                })
              )
          }
        }}
        options={spaces}
        filterOptions={createFilterOptions({
          stringify: (option) => `${option.spaceName} ${option.spaceId}`
        })}
        isOptionEqualToValue={(option, value) =>
          option.spaceId === value.spaceId
        }
        getOptionLabel={(option) => option.spaceName}
        renderOption={(props, option) => (
          <AutocompleteOption {...props} key={option.spaceId}>
            <ListItemContent>
              <Typography level="body-sm" textColor="text.primary">
                {option.spaceName}
              </Typography>
              <Typography level="body-xs" fontWeight="400" noWrap>
                {option.spaceId}
              </Typography>
            </ListItemContent>
          </AutocompleteOption>
        )}
      />
    )
  )
}
