import { Operation } from "@liveops-portal/lib"
import { Button } from "@mui/joy"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { OperationReason } from "@/components/operation-reason/operation-reason"
import { isOperationActive } from "@/helpers/operation-reason"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { useResetToFProgressionMutation } from "@/store/api/battlepass"
import { useFindPlayerProfileQuery } from "@/store/api/player"
import {
  setActiveOperation,
  resetOperation,
  selectActiveOperation
} from "@/store/slices/audit"
import { selectPlayer, selectSpace } from "@/store/slices/context"

export const ResetToFProgression: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const spaceId = useAppSelector(selectSpace)
  const { type, id } = useAppSelector(selectPlayer)
  const activeOperation = useAppSelector(selectActiveOperation)
  const { currentData: playerProfile } = useFindPlayerProfileQuery(
    { spaceId, id, type },
    { skip: !spaceId || !type || !id }
  )

  const [resetToFProgression, resetToFProgressionMeta] =
    useResetToFProgressionMutation()
  const resetToFProgressionOperation: Operation = ["ToFProgression", "reset"]
  const isResetToFProgressionOperationActive = isOperationActive(
    activeOperation,
    resetToFProgressionOperation
  )
  const isLoadingReset =
    isResetToFProgressionOperationActive || resetToFProgressionMeta.isLoading

  const onResetToFProgressionHandler = () => {
    if (
      isResetToFProgressionOperationActive &&
      playerProfile?.PlayfabProfileId
    ) {
      resetToFProgression({
        spaceId,
        accountId: playerProfile.PlayfabProfileId
      })
    }
  }

  useEffect(() => {
    if (resetToFProgressionMeta.isSuccess || resetToFProgressionMeta.isError) {
      dispatch(resetOperation())
    }
  }, [
    dispatch,
    resetToFProgressionMeta.isError,
    resetToFProgressionMeta.isSuccess
  ])

  return (
    !!playerProfile && (
      <>
        <OperationReason
          open={isResetToFProgressionOperationActive}
          onSubmit={onResetToFProgressionHandler}
          autofocus
          closeable
        />

        <Button
          variant="soft"
          color="warning"
          loading={isLoadingReset}
          disabled={isLoadingReset || !playerProfile?.PlayfabProfileId}
          onClick={() => {
            dispatch(setActiveOperation(resetToFProgressionOperation))
          }}
        >
          {t("label.resetToFProgression")}
        </Button>
      </>
    )
  )
}
