import { ApiError } from "@liveops-portal/lib"
import { Stack, Typography } from "@mui/joy"
import { Grid2 as Grid } from "@mui/material"
import { MessageAlert } from "iconoir-react"
import React from "react"
import { useTranslation } from "react-i18next"
import { CustomErrorsList } from "@/components/custom-errors-list/custom-errors-list"
import { PlayerCustomizations } from "@/components/player-customizations/player-customizations"
import { PlayerDecks } from "@/components/player-decks/player-decks"
import { PlayerIds } from "@/components/player-ids/player-ids"
import { PlayerInfo } from "@/components/player-info/player-info"
import { PlayerPopulations } from "@/components/player-populations/player-populations"
import { Spinner } from "@/components/spinner/spinner"
import { useAppSelector } from "@/hooks/store"
import { useDataType } from "@/hooks/useDataType"
import { useUniservicesConfigStatus } from "@/hooks/useUniservicesConfigStatus"
import { useFindPlayerProfileQuery } from "@/store/api/player"
import { selectPlayer, selectSpace } from "@/store/slices/context"

export const PlayerFinderPage: React.FC = () => {
  const { t, i18n } = useTranslation()
  const spaceId = useAppSelector(selectSpace)
  const { type, id } = useAppSelector(selectPlayer)
  const config = useUniservicesConfigStatus()
  const playerProfile = useFindPlayerProfileQuery(
    { spaceId, id, type },
    { skip: !spaceId || !type || !id }
  )

  const spellcasters = useDataType("spellcasters")
  const catalysts = useDataType("catalysts")
  const spells = useDataType("spells")

  const isFetching =
    config.isFetching ||
    playerProfile.isFetching ||
    spellcasters.isFetching ||
    catalysts.isFetching ||
    spells.isFetching

  const { errCode, message } = (playerProfile.error as ApiError) || {}

  return !spaceId ? (
    <Typography>
      {t("message.uninitialized.root", {
        parent: "item.space"
      })}
    </Typography>
  ) : (
    <>
      <Spinner loading={isFetching} />

      {!!Object.keys(config.errors).length && !config.isFetching && (
        <CustomErrorsList errors={config.errors} />
      )}

      {!id ||
        (playerProfile.isError && (
          <Typography>
            {t("message.uninitialized.root", {
              parent: "item.player"
            })}
          </Typography>
        ))}

      {playerProfile.isError && (
        <Stack sx={{ gap: 2 }}>
          <Typography startDecorator={<MessageAlert />} color="warning">
            {errCode
              ? i18n.exists(`error.player.${errCode}`, { id, spaceId })
                ? t(`error.player.${errCode}`, { id, spaceId })
                : message
              : t("error.player.generic")}
          </Typography>
        </Stack>
      )}

      {id && !isFetching && playerProfile.currentData && (
        <Stack sx={{ gap: 2, flexGrow: 1 }}>
          <Stack sx={{ flexGrow: 1, gap: 3, position: "relative" }}>
            <Grid container spacing={2} columns={12}>
              <PlayerInfo />
              <PlayerIds />
              <PlayerPopulations />
            </Grid>
            <Grid container spacing={2} columns={12}>
              <PlayerDecks />
              <PlayerCustomizations />
            </Grid>
          </Stack>
        </Stack>
      )}
    </>
  )
}
