import { AllGamesQuery, ApiError, Game } from "@liveops-portal/lib"
import notify from "@/helpers/notify"
import i18next from "@/i18n"
import { api } from "@/store/api"

export const gameApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllGames: builder.query<Game[], AllGamesQuery>({
      query: ({ includeMarkedForDeletion }) => ({
        url: `games`,
        params: { includeMarkedForDeletion }
      }),
      onQueryStarted(_, { queryFulfilled }) {
        queryFulfilled.catch(({ error }) => {
          notify.error({
            message: i18next.t("api.getAllGames.error"),
            error: error as ApiError
          })
        })
      }
    })
  }),
  overrideExisting: false
})

export const { useGetAllGamesQuery } = gameApi
