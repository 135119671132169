import {
  AllBundlesQuery,
  ApiError,
  Bundle,
  DeleteBundleMutation,
  UpsertBundleMutation
} from "@liveops-portal/lib"
import notify from "@/helpers/notify"
import i18next from "@/i18n"
import { api } from "@/store/api/index"

export const bundleApi = api
  .enhanceEndpoints({ addTagTypes: ["Bundle", "Season"] })
  .injectEndpoints({
    endpoints: (builder) => ({
      getBundles: builder.query<Bundle[], AllBundlesQuery>({
        query: ({ gameId }) => ({
          url: "bundles",
          params: { gameId }
        }),
        providesTags: ["Bundle"],
        onQueryStarted({ gameId }, { queryFulfilled }) {
          queryFulfilled.catch(({ error }) => {
            notify.error({
              message: i18next.t("api.getBundles.error", { gameId }),
              error: error as ApiError
            })
          })
        }
      }),
      upsertBundle: builder.mutation<Bundle, UpsertBundleMutation>({
        query: ({
          gameId,
          bundleName,
          seasonIds,
          bundleId,
          qdOperationReason
        }) => {
          return {
            url: "bundles",
            method: "PUT",
            params: { gameId },
            body: { bundleName, seasonIds, bundleId },
            headers: {
              "QD-Operation-Reason": qdOperationReason
            }
          }
        },
        invalidatesTags: ["Bundle", { type: "Season", id: "ALL" }],
        onQueryStarted({ bundleName }, { queryFulfilled }) {
          queryFulfilled
            .then(() => {
              notify.success(
                i18next.t("api.upsertBundle.success", { bundleName })
              )
            })
            .catch(({ error }) => {
              notify.error({
                message: i18next.t("api.upsertBundle.error"),
                error: error as ApiError
              })
            })
        }
      }),
      deleteBundle: builder.mutation<void, DeleteBundleMutation>({
        query: ({ gameId, bundleId }) => ({
          url: `bundles/${bundleId}`,
          method: "DELETE",
          params: { gameId }
        }),
        invalidatesTags: ["Bundle", { type: "Season", id: "ALL" }],
        onQueryStarted(_, { queryFulfilled }) {
          queryFulfilled
            .then(() => {
              notify.success(i18next.t("api.deleteBundle.success"))
            })
            .catch(({ error }) => {
              notify.error({
                message: i18next.t("api.deleteBundle.error"),
                error: error as ApiError
              })
            })
        }
      })
    })
  })

export const {
  useGetBundlesQuery,
  useUpsertBundleMutation,
  useDeleteBundleMutation
} = bundleApi
