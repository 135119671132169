import { sortObjectsWithKeyword } from "@liveops-portal/lib"
import { Grid, Stack, Typography } from "@mui/joy"
import React from "react"
import { useTranslation } from "react-i18next"
import { ApplicationCard } from "@/components/application-card/application-card"
import { CopyToClipboard } from "@/components/copy-to-clipboard/copy-to-clipboard"
import { Spinner } from "@/components/spinner/spinner"
import { useAppSelector } from "@/hooks/store"
import { useGetAllApplicationsByGameIdQuery } from "@/store/api/application"
import { useGetAllBuildsByGameIdQuery } from "@/store/api/build"
import { useGetAllSpacesQuery } from "@/store/api/space"
import { selectEnv, selectGame } from "@/store/slices/context"

export const ApplicationsPage: React.FC = () => {
  const { t } = useTranslation()
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)

  const { data: spaces, isFetching: isFetchingSpaces } = useGetAllSpacesQuery(
    { env },
    { skip: !env }
  )

  const { data: applications, isFetching: isFetchingApplications } =
    useGetAllApplicationsByGameIdQuery(
      { gameId, env },
      { skip: !gameId || !env }
    )

  const { data: builds, isFetching: isFetchingBuilds } =
    useGetAllBuildsByGameIdQuery({ gameId, env }, { skip: !gameId || !env })

  const isFetching =
    isFetchingSpaces || isFetchingApplications || isFetchingBuilds

  return (
    <>
      <Spinner loading={isFetching} />

      {applications?.length ? (
        applications.map(({ name, appId }) => (
          <Stack key={appId} gap={2}>
            <Stack direction="row" alignItems="center" gap={1}>
              <Typography level="h3">{name}</Typography>

              <Typography level="body-xs" textColor="text.tertiary" mt={1}>
                <CopyToClipboard>{appId}</CopyToClipboard>
              </Typography>
            </Stack>
            {builds?.[appId]?.length ? (
              <Grid container spacing={2} columns={12}>
                {sortObjectsWithKeyword(builds[appId], "buildId").map(
                  ({ buildId, spaceId }, i) => (
                    <ApplicationCard
                      key={`${buildId}-${i}`}
                      buildId={buildId}
                      space={spaces?.find((e) => e.spaceId === spaceId)}
                    />
                  )
                )}
              </Grid>
            ) : (
              <Typography>
                {t("message.noItems.parent", {
                  item: "item.builds",
                  parent: "item.application"
                })}
              </Typography>
            )}
          </Stack>
        ))
      ) : (
        <Typography>
          {t("message.noItems.parent", {
            item: "item.applications",
            parent: "item.game"
          })}
        </Typography>
      )}
    </>
  )
}
