import { mockSpace } from "../mocks"
import { Application } from "../typings"

export const mockApplication: Application = {
  appId: "3cc6eb5e-f191-408b-9db5-1fe4ae8590f7",
  name: "applicationName-1",
  defaultSpaceId: mockSpace.spaceId,
  markedForDeletion: false
}

export const mockAllApplicationsByGameId: Application[] = [mockApplication]
