import { insertIf, Environments } from "@liveops-portal/lib"
import { Divider, Stack, Typography } from "@mui/joy"
import dayjs from "dayjs"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { CardItem } from "@/components/card-item/card-item"
import { DeploymentSet } from "@/components/deployment-set/deployment-set"
import { Spinner } from "@/components/spinner/spinner"
import { useAppSelector } from "@/hooks/store"
import { usePageActions } from "@/hooks/usePageActions"
import { useGetBundlesQuery } from "@/store/api/bundle"
import { useGetActiveDeploymentQuery } from "@/store/api/deployment"
import {
  selectEnv,
  selectGame,
  selectSpace,
  selectStage
} from "@/store/slices/context"

export const ActiveDeploymentPage: React.FC = () => {
  const { t } = useTranslation()
  const stage = useAppSelector(selectStage)
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const spaceId = useAppSelector(selectSpace)

  const {
    data: deployment,
    isUninitialized,
    isFetching: isFetchingDeployment
  } = useGetActiveDeploymentQuery(
    { gameId, spaceId },
    {
      skip: !gameId || !spaceId
    }
  )

  const { bundle, isFetching: isFetchingBundle } = useGetBundlesQuery(
    { gameId },
    {
      skip: !deployment,
      selectFromResult: ({ data, isFetching }) => ({
        bundle: data?.find(
          (bundle) => bundle.bundleId === deployment?.bundleId
        ),
        isFetching
      })
    }
  )

  const validEnd =
    stage === Environments.ONLINEDEV
      ? env === Environments.ONLINEDEV
      : env !== Environments.ONLINEDEV

  usePageActions([<DeploymentSet />])

  const isOutdated = useMemo(() => {
    if (!bundle || !deployment) return false

    return bundle.bundleVersion > deployment.bundleVersion
  }, [bundle, deployment])

  return !validEnd ? (
    <Typography color="warning">
      {t("message.deployment.invalidEnv", { env, stage })}
    </Typography>
  ) : isUninitialized ? (
    <Typography>
      {t("message.uninitialized.root", {
        parent: "item.space"
      })}
    </Typography>
  ) : (
    <>
      <Spinner loading={isFetchingDeployment || isFetchingBundle} />

      {bundle && deployment ? (
        <Stack gap={2}>
          <Typography level="h3">
            {t("label.deployment.deployedBundleInformation")}
          </Typography>
          <CardItem
            title={{
              content: `${bundle.bundleName}`
            }}
            chips={[
              ...insertIf(isOutdated, {
                content: t("state.outdated"),
                color: "danger" as const
              })
            ]}
            subtitles={[
              ...insertIf(isOutdated, {
                content: t("error.deployment.selectBundleAgain"),
                color: "warning" as const,
                props: { fontStyle: "italic" }
              }),
              {
                label: t("label.deployment.deploymentDate"),
                content: dayjs(deployment.deploymentDate).format(
                  "YYYY-MM-DD HH:mm:ss z"
                )
              },
              {
                label: t("label.deployment.deploymentId"),
                content: deployment.deploymentId
              },
              {
                label: t("label.bundle.deployedBundleVersion"),
                content: deployment.bundleVersion
              },
              { content: <Divider inset="context" sx={{ mb: 1, mt: 1 }} /> },
              {
                label: t("label.bundle.bundleId"),
                content: bundle.bundleId
              },
              {
                label: t("label.bundle.latestBundleVersion"),
                content: bundle.bundleVersion
              }
            ]}
          />
        </Stack>
      ) : (
        <Typography>{t("error.deployment.emptyDeployment")}</Typography>
      )}
    </>
  )
}
