import "./instrument"
import {
  EventMessage,
  AuthenticationResult,
  InteractionRequiredAuthError
} from "@azure/msal-browser"
import { MsalProvider } from "@azure/msal-react"
import {
  CssBaseline,
  GlobalStyles,
  CssVarsProvider as JoyCssVarsProvider
} from "@mui/joy"
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID
} from "@mui/material"
import * as Sentry from "@sentry/react"
import dayjs from "dayjs"
import DurationPlugin from "dayjs/plugin/duration"
import isTodayPlugin from "dayjs/plugin/isToday"
import isYesterdayPlugin from "dayjs/plugin/isYesterday"
import UTCPlugin from "dayjs/plugin/utc"
import { IconoirProvider } from "iconoir-react"
import React from "react"
import ReactDOM from "react-dom/client"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { I18nextProvider } from "react-i18next"
import { Provider as StoreProvider } from "react-redux"
import { MSAL_INSTANCE } from "@/constants/auth"
import i18next from "@/i18n"
import { Router } from "@/router/router"
import store from "@/store"
import { globalStyles, toastifyStyles } from "@/styles"
import theme from "@/styles/theme"

// Dynamically add data-attributes (such as data-testid) to HTML Attributes types
type DataAttributeKey = `data-${string}`
declare module "react" {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    [dataAttribute: DataAttributeKey]: unknown
  }
}

const prefersDarkMode =
  window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches

dayjs.extend(DurationPlugin)
dayjs.extend(UTCPlugin)
dayjs.extend(isTodayPlugin)
dayjs.extend(isYesterdayPlugin)

// Localization management
const savedLocalization = localStorage.getItem("localization")

if (savedLocalization) {
  i18next.changeLanguage(savedLocalization)
  Sentry.setTag("locale", savedLocalization)
} else {
  localStorage.setItem("localization", "en-US")
  Sentry.setTag("locale", "en-US")
}

// TODO: Add color to MaterialCssVarsProvider theme
const materialTheme = materialExtendTheme()

MSAL_INSTANCE.initialize().then(() => {
  const accounts = MSAL_INSTANCE.getAllAccounts()
  if (accounts.length > 0) {
    MSAL_INSTANCE.setActiveAccount(accounts[0])
    Sentry.setUser({ email: accounts[0].username })
  }

  MSAL_INSTANCE.enableAccountStorageEvents()

  MSAL_INSTANCE.addEventCallback(
    ({ eventType, payload, error }: EventMessage) => {
      if (eventType.includes("Success") && payload) {
        const { account } = payload as AuthenticationResult
        MSAL_INSTANCE.setActiveAccount(account)
        Sentry.setUser({ email: account.username })
      }

      if (error && !(error instanceof InteractionRequiredAuthError)) {
        console.error(error)
        Sentry.withScope((scope) => {
          "errorMessage" in error &&
            scope.setExtra("errorMessage", error.errorMessage)
          "subError" in error && scope.setExtra("subError", error.subError)
          Sentry.captureException(error)
        })
      }
    }
  )

  ReactDOM.createRoot(document.getElementById("root")!).render(
    <React.StrictMode>
      <MsalProvider instance={MSAL_INSTANCE}>
        <StoreProvider store={store}>
          <MaterialCssVarsProvider
            defaultMode={prefersDarkMode ? "dark" : "light"}
            modeStorageKey="mode"
            colorSchemeStorageKey="color-scheme"
            theme={{ [MATERIAL_THEME_ID]: materialTheme }}
          >
            <JoyCssVarsProvider
              defaultMode={prefersDarkMode ? "dark" : "light"}
              modeStorageKey="mode"
              colorSchemeStorageKey="color-scheme"
              theme={theme}
            >
              <CssBaseline />
              <GlobalStyles styles={globalStyles} />
              <GlobalStyles styles={toastifyStyles} />
              <IconoirProvider
                iconProps={{
                  className: "iconoir",
                  strokeWidth: 2
                }}
              >
                <I18nextProvider i18n={i18next}>
                  <HelmetProvider>
                    <Helmet defaultTitle={i18next.t("app.name")} />
                    <Router />
                  </HelmetProvider>
                </I18nextProvider>
              </IconoirProvider>
            </JoyCssVarsProvider>
          </MaterialCssVarsProvider>
        </StoreProvider>
      </MsalProvider>
    </React.StrictMode>
  )
})
