import useUrlState from "@ahooksjs/use-url-state"
import { PlayerId, PlayerIds, PlayerIdType } from "@liveops-portal/lib"
import { Divider, IconButton, Input, Option, Select } from "@mui/joy"
import { Search } from "iconoir-react"
import React, { useEffect, useMemo, useRef } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { EntityChip } from "@/components/entity-chip/entity-chip"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { useRoutePath } from "@/hooks/useRoutePath"
import { useFindPlayerProfileQuery } from "@/store/api/player"
import { selectPlayer, selectSpace, setPlayerId } from "@/store/slices/context"

export const PlayerSelector: React.FC = () => {
  const { t } = useTranslation()
  const mounted = useRef(false)
  const currentPath = useRoutePath()
  const dispatch = useAppDispatch()
  const spaceId = useAppSelector(selectSpace)
  const { type, id } = useAppSelector(selectPlayer)
  const [urlState, setUrl] = useUrlState<PlayerIds>()
  const presetId: PlayerId = useMemo(
    () =>
      Object.keys(urlState).reduce((acc, k) => {
        const key = k as PlayerIdType
        acc = { type: key, id: urlState[key] }
        return acc
      }, {} as PlayerId),
    [urlState]
  )
  const {
    handleSubmit,
    getValues,
    control,
    setValue,
    formState: { isValid }
  } = useForm<PlayerId>({
    mode: "all",
    defaultValues: { id: presetId.id || "", type: presetId.type || "accountId" }
  })
  const { isFetching, refetch } = useFindPlayerProfileQuery(
    {
      spaceId,
      type,
      id
    },
    { skip: !spaceId || !type || !id }
  )
  const onSubmit: SubmitHandler<PlayerId> = (data) => {
    if (data.type === type && data.id === id) refetch()
    if (data.type) {
      setUrl({ [data.type]: data.id })
    }
  }

  const disabled = isFetching || !spaceId

  useEffect(() => {
    if (currentPath?.includes("players") && spaceId) {
      if (!mounted.current) mounted.current = true
      if (presetId.id && presetId.type) {
        dispatch(setPlayerId(presetId))
        setValue("id", presetId.id)
        setValue("type", presetId.type)
      }
    } else {
      if (mounted.current) {
        dispatch(setPlayerId({ id: null, type: getValues("type") }))
        setValue("id", "")
      }
    }
  }, [spaceId, dispatch, getValues, presetId, setValue, currentPath])

  return (
    currentPath?.includes("players") && (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="id"
          control={control}
          render={({ field: inputField }) => (
            <Input
              {...inputField}
              value={inputField.value || ""}
              aria-label={t("label.player.playerId")}
              disabled={disabled}
              placeholder={t("placeholder.player", { type: getValues("type") })}
              startDecorator={<EntityChip type="player" sx={{ mr: 1 }} />}
              endDecorator={
                <>
                  <Divider orientation="vertical" />
                  <Controller
                    name="type"
                    control={control}
                    render={({ field: selectField }) => (
                      <Select
                        {...selectField}
                        variant="plain"
                        disabled={disabled}
                        slotProps={{
                          listbox: {
                            variant: "outlined"
                          }
                        }}
                        sx={{ "&:hover": { bgcolor: "transparent" } }}
                      >
                        {Object.keys(PlayerIdType).map((key) => (
                          <Option
                            key={key}
                            value={key}
                            disabled={key !== "accountId"}
                          >
                            {t(`label.player.type.${key}`)}
                          </Option>
                        ))}
                      </Select>
                    )}
                  />
                  <Divider orientation="vertical" />
                  <IconButton
                    type="submit"
                    variant="solid"
                    color="primary"
                    disabled={disabled || !isValid}
                    loading={isFetching}
                    aria-label={t(`action.search`)}
                    title={t(`action.search`)}
                    sx={{
                      ml: 0.5
                    }}
                    slotProps={{
                      loadingIndicator: { "data-testid": "loading" }
                    }}
                  >
                    <Search />
                  </IconButton>
                </>
              }
              sx={{ "--Input-gap": "0px", width: 500 }}
              slotProps={{
                input: {
                  "data-1p-ignore": "",
                  "data-lpignore": "",
                  "data-bwignore": ""
                }
              }}
            />
          )}
        />
      </form>
    )
  )
}
