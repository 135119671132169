import { mockGame } from "../mocks/game.mocks"
import { GameServerBuild } from "../typings"

export const mockGameServerBuild: GameServerBuild = {
  gameId: mockGame.gameId,
  buildId: "buildId",
  buildType: "release",
  codeChangeList: "123",
  dataChangeList: "456",
  deploymentType: "ZIP",
  state: "READY_FOR_USE",
  lastUpdate: "2000-10-31T01:30:00.000000Z",
  buildName: "buildName",
  uploadPath: "/path/to/build",
  preserved: true,
  deployments: [
    {
      spaceId: "spaceId",
      buildId: "buildId",
      numberOfCores: 2,
      cpuType: "Amd",
      state: "Deployed"
    }
  ]
}
