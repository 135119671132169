import { Actions, Bundle, Environments } from "@liveops-portal/lib"
import { Button } from "@mui/joy"
import { CloudUpload } from "iconoir-react"
import React, { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { DeployModal } from "@/components/deploy-modal/deploy-modal"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { useCreateActiveDeploymentMutation } from "@/store/api/deployment"
import {
  selectEnv,
  selectGame,
  selectSpace,
  selectStage
} from "@/store/slices/context"

type FormData = Pick<Bundle, "bundleId" | "bundleVersion">

export const DeploymentSet: React.FC = () => {
  const dispatch = useAppDispatch()
  const methods = useForm<FormData>({ mode: "all" })
  const { reset: resetForm } = methods
  const stage = useAppSelector(selectStage)
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const spaceId = useAppSelector(selectSpace)
  const { t } = useTranslation()
  const [open, setOpen] = useState<boolean>(false)

  const [createActiveDeployment, mutation] = useCreateActiveDeploymentMutation()
  const { isSuccess, isLoading: isLoadingCreate } = mutation

  const onCloseHandler = useCallback(() => {
    resetForm()
    setOpen(false)
  }, [resetForm])

  const onSubmitHandler = (data: FormData) =>
    createActiveDeployment({ ...data, gameId, spaceId })

  useEffect(() => {
    if (isSuccess) {
      onCloseHandler()
    }
  }, [dispatch, isSuccess, onCloseHandler])

  const validEnd =
    stage === Environments.ONLINEDEV
      ? env === Environments.ONLINEDEV
      : env !== Environments.ONLINEDEV

  return (
    validEnd &&
    !!spaceId && (
      <>
        <Button
          variant="outlined"
          onClick={() => setOpen(true)}
          startDecorator={<CloudUpload />}
        >
          {t("action.deploy")}
        </Button>

        <DeployModal
          open={open}
          loading={isLoadingCreate}
          action={Actions.deploy}
          methods={methods}
          onSubmit={onSubmitHandler}
          onClose={onCloseHandler}
        />
      </>
    )
  )
}
