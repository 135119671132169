import { last } from "@liveops-portal/lib"
import { Typography, Stack } from "@mui/joy"
import { PropsWithChildren, ReactElement, useState } from "react"
import React from "react"
import { Helmet } from "react-helmet-async"
import { useTranslation } from "react-i18next"
import { useMatches } from "react-router-dom"
import { PageContext } from "@/hooks/usePageActions"

export const Page = ({ children }: PropsWithChildren) => {
  const { t } = useTranslation()
  const matches = useMatches()
  const currentPage = last(matches)
  const title = currentPage?.handle?.title || currentPage?.handle?.name
  const [pageActions, setPageActions] = useState<ReactElement[]>([])

  return (
    <>
      <Helmet titleTemplate={`%s - ${t("app.name")}`}>
        <title>{t(title)}</title>
      </Helmet>
      <PageContext.Provider
        value={{ pageActions: [pageActions, setPageActions] }}
      >
        <Stack
          direction="row"
          sx={{
            mb: 2,
            gap: 3,
            alignContent: "center",
            justifyContent: "space-between"
          }}
        >
          <Typography level="h2" component="h1" mt="-5px">
            {t(title)}
          </Typography>
          {!!pageActions?.length && (
            <Stack direction="row" sx={{ gap: 1 }}>
              {React.Children.map(pageActions, (child) => child)}
            </Stack>
          )}
        </Stack>

        <Stack component="section" sx={{ flexGrow: 1 }}>
          {children}
        </Stack>
      </PageContext.Provider>
    </>
  )
}
