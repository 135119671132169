import { Chip, Option, Select } from "@mui/joy"
import React, { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { generatePath, useNavigate, useParams } from "react-router-dom"
import { DEFAULT_GAMEID } from "@/constants/context"
import { isValidParam } from "@/helpers/params"
import { useAppDispatch, useAppSelector } from "@/hooks/store"
import { useRoutePath } from "@/hooks/useRoutePath"
import { useGetEnvironmentsQuery } from "@/store/api/environment"
import {
  selectEnv,
  setEnv,
  setGameId,
  setSeasonId,
  setSpaceId
} from "@/store/slices/context"

export const EnvSelector: React.FC = () => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const currentPath = useRoutePath()
  const env = useAppSelector(selectEnv)
  const { env: envParam } = useParams()
  const { data: envs } = useGetEnvironmentsQuery()

  const isValidEnv = useMemo(
    () => envParam && isValidParam(envParam, envs),
    [envParam, envs]
  )

  useEffect(() => {
    if (envs === undefined) return
    if (!isValidEnv || !envParam) {
      navigate(
        generatePath("/:env/overview/:gameId?", {
          env,
          gameId: DEFAULT_GAMEID
        }),
        { replace: true }
      )
      return
    }

    if (envParam && env !== envParam) {
      dispatch(setEnv(envParam))
    }
  }, [dispatch, envs, env, envParam, isValidEnv, navigate])

  return (
    envs?.length && (
      <Select
        color={env}
        aria-label={t("label.envSelector")}
        listboxId="env-selector"
        startDecorator={<Chip color={env}>{t("item.env")}</Chip>}
        variant="soft"
        value={env}
        onChange={(_, newEnv) => {
          dispatch(setGameId(DEFAULT_GAMEID))
          dispatch(setSpaceId(null))
          dispatch(setSeasonId(null))

          currentPath &&
            navigate(
              generatePath(currentPath, {
                env: newEnv,
                gameId: DEFAULT_GAMEID,
                spaceId: null,
                seasonId: null
              })
            )
        }}
        slotProps={{
          listbox: {
            variant: "outlined",
            color: "neutral",
            sx: { p: 0 }
          }
        }}
      >
        {envs.map((environment) => (
          <Option
            variant="plain"
            color="neutral"
            key={environment}
            label={environment}
            value={environment}
          >
            {environment}
          </Option>
        ))}
      </Select>
    )
  )
}
