import { Card, CardContent, List, ListItem, Stack, Typography } from "@mui/joy"
import { Grid2 as Grid } from "@mui/material"
import dayjs from "dayjs"
import React from "react"
import { useTranslation } from "react-i18next"
import { PlayerDataDelete } from "@/components/player-data-delete/player-data-delete"
import { ResetToFProgression } from "@/components/reset-tof-progression/reset-tof-progression"
import { useAppSelector } from "@/hooks/store"
import { useFindPlayerProfileQuery } from "@/store/api/player"
import { selectSpace, selectPlayer } from "@/store/slices/context"

export const PlayerInfo: React.FC = () => {
  const { t } = useTranslation()
  const spaceId = useAppSelector(selectSpace)
  const { type, id } = useAppSelector(selectPlayer)
  const { currentData: playerProfile } = useFindPlayerProfileQuery(
    { spaceId, id, type },
    { skip: !spaceId || !type || !id }
  )

  return (
    !!playerProfile && (
      <Grid size={{ xs: 6, lg: 4 }} component={Stack} sx={{ gap: 2 }}>
        <Typography level="h3">{t("label.player.playerInfo")}</Typography>
        <Card variant="outlined" sx={{ flexGrow: 1 }}>
          <CardContent sx={{ gap: 2 }}>
            <List
              sx={{
                p: 0,
                "--ListItem-minHeight": 0,
                "--ListItem-paddingX": 0
              }}
            >
              {!!playerProfile.playtime && (
                <ListItem>
                  <Typography level="title-sm">
                    {t("label.player.playtime")}:
                  </Typography>
                  <Typography level="body-sm">
                    {dayjs
                      .duration(playerProfile.playtime, "ms")
                      .format("D[d] H[h] m[m]")}
                  </Typography>
                </ListItem>
              )}
              {!!playerProfile.lastOnline && (
                <ListItem>
                  <Typography level="title-sm">
                    {t("label.player.lastOnline")}:
                  </Typography>
                  <Typography level="body-sm">
                    {dayjs(playerProfile.lastOnline).format(
                      "YYYY-MM-DD HH:mm:ss z"
                    )}
                  </Typography>
                </ListItem>
              )}
              <ListItem>
                <Typography level="title-sm">
                  {t("label.player.ranking")}:
                </Typography>
                <Typography
                  level="body-sm"
                  fontStyle={playerProfile.data?.ranking ? "normal" : "italic"}
                >
                  {playerProfile.data?.ranking || t("common.none")}
                </Typography>
              </ListItem>
            </List>

            <Stack
              sx={{
                flexDirection: "row",
                gap: 1,
                "*": { flexGrow: 1, flexBasis: "50%" }
              }}
            >
              <ResetToFProgression />
              <PlayerDataDelete />
            </Stack>
          </CardContent>
        </Card>
      </Grid>
    )
  )
}
