import {
  AllGameDataBySeasonIdQuery,
  AllGameDataVersionsQuery,
  ApiError,
  GameData,
  GameDataVersion
} from "@liveops-portal/lib"
import notify from "@/helpers/notify"
import i18next from "@/i18n"
import { api } from "@/store/api"

export const gameDataApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getGameDataVersions: builder.query<string[], AllGameDataVersionsQuery>({
      query: ({ gameId }) => ({
        url: "game-data/versions",
        params: { gameId }
      }),
      onQueryStarted({ gameId }, { queryFulfilled }) {
        queryFulfilled.catch(({ error }) => {
          notify.error({
            message: i18next.t("api.getGameDataVersions.error", { gameId }),
            error: error as ApiError
          })
        })
      },
      transformResponse: (gameDataVersions: GameDataVersion[]) => {
        return gameDataVersions
          ?.filter(({ state }) => state === "READY_FOR_USE")
          .map(({ dataVersion }) => dataVersion.toString())
      }
    }),
    getGameDataBySeasonId: builder.query<
      Record<string, GameData[]>,
      AllGameDataBySeasonIdQuery
    >({
      query: ({ gameId, seasonId, dataTypes }) => ({
        url: `game-data`,
        params: { gameId, seasonId, dataTypes }
      }),
      onQueryStarted({ seasonId }, { queryFulfilled }) {
        queryFulfilled.catch(({ error }) =>
          notify.error({
            message: i18next.t("api.getGameDataBySeasonId.error", { seasonId }),
            error: error as ApiError
          })
        )
      }
    })
  }),
  overrideExisting: false
})

export const { useGetGameDataVersionsQuery, useGetGameDataBySeasonIdQuery } =
  gameDataApi
