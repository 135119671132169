import { intersection, not, union } from "@liveops-portal/lib"
import {
  Card,
  Checkbox,
  Stack,
  StackProps,
  List,
  CardOverflow,
  Typography,
  ListItem,
  IconButton,
  Link
} from "@mui/joy"
import {
  FastArrowLeft,
  FastArrowRight,
  NavArrowLeft,
  NavArrowRight
} from "iconoir-react"
import React, { useCallback, useEffect } from "react"
import { Controller, useFormContext } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { FormLabel } from "@/components/form-label/form-label"
import { Spinner } from "@/components/spinner/spinner"

interface Props extends Omit<StackProps, "onSelect"> {
  name: string
  options: { label: string; id: string | number }[]
  preSelected?: (string | number)[]
  onSelect?: (selected: (string | number)[]) => void
  onSelectAll?: (selected: (string | number)[]) => void
  onUnselect?: (selected: (string | number)[]) => void
  onUnselectAll?: (selected: (string | number)[]) => void
  disabled?: boolean
  loading?: boolean
}

export const TransferList = ({
  name,
  options,
  preSelected,
  onSelect,
  onSelectAll,
  onUnselect,
  onUnselectAll,
  disabled = false,
  loading = false,
  sx,
  ...props
}: Props) => {
  const { t } = useTranslation()
  const { control, setValue } = useFormContext()
  const [checked, setChecked] = React.useState<(string | number)[]>([])
  const [_options, setOptions] = React.useState<(string | number)[]>(
    options.map(({ id }) => id).filter((id) => !preSelected?.includes(id))
  )
  const [selected, setSelected] = React.useState<(string | number)[]>(
    preSelected || []
  )
  const sort = useCallback(
    (a: string | number, b: string | number) => {
      const _a = options.find(({ id }) => id === a)!.label
      const _b = options.find(({ id }) => id === b)!.label
      return _a.localeCompare(_b)
    },
    [options]
  )

  const optionsChecked = intersection(checked, _options)
  const selectedChecked = intersection(checked, selected)
  const amountChecked = (items: (string | number)[]) =>
    intersection(checked, items).length

  const handleToggleAll = (items: (string | number)[]) => () => {
    if (amountChecked(items) === items.length) {
      setChecked(not(checked, items))
    } else {
      setChecked(union(checked, items))
    }
  }

  const handleToggle = (value: string | number) => () => {
    const currentIndex = checked.indexOf(value)
    const newChecked = [...checked]

    if (currentIndex === -1) {
      newChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    setChecked(newChecked)
  }

  const handleSelectAll = () => {
    const _selected = [...selected.concat(_options)].sort(sort)
    setOptions([])
    setSelected(_selected)
    onSelectAll?.(_selected)
  }

  const handleSelect = () => {
    const _selected = [...selected.concat(optionsChecked)].sort(sort)
    setChecked(not(checked, optionsChecked))
    setOptions(not(_options, optionsChecked))
    setSelected(_selected)
    onSelect?.(_selected)
  }

  const handleUnselect = () => {
    const _selected = not(selected, selectedChecked)
    setChecked(not(checked, selectedChecked))
    setOptions([..._options.concat(selectedChecked)].sort(sort))
    setSelected(_selected)
    onUnselect?.(_selected)
  }

  const handleUnselectAll = () => {
    setOptions([..._options.concat(selected)].sort(sort))
    setSelected([])
    onUnselectAll?.([])
  }

  const renderList = (
    title: React.ReactNode,
    items: (string | number)[],
    testId?: string
  ) => (
    <Card
      size="sm"
      variant="outlined"
      data-testid={testId}
      sx={{
        p: 0,
        gap: 0,
        flexGrow: 1,
        maxWidth: "calc(50% - 32px)"
      }}
    >
      <Spinner loading={loading} />
      {!loading && (
        <>
          <CardOverflow
            variant="soft"
            sx={{
              py: 1,
              px: 1.5,
              borderBottom: "1px solid",
              borderColor: "divider"
            }}
          >
            <Stack
              sx={{
                gap: 1,
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between"
              }}
            >
              <Typography
                sx={{
                  "--FormLabel-asteriskColor": ({ palette }) =>
                    palette.danger[500],
                  display: "flex",
                  gap: 1
                }}
              >
                <FormLabel>{title}</FormLabel>
                <Typography
                  fontSize="xs"
                  mt="1px"
                  data-testid="amountChecked"
                >{`${amountChecked(items)}/${items.length}`}</Typography>
              </Typography>
              <Link
                fontSize="sm"
                disabled={items.length === 0 || disabled}
                onClick={handleToggleAll(items)}
              >
                {amountChecked(items) === items.length && items.length !== 0
                  ? t("action.selectNone")
                  : t("action.selectAll")}
              </Link>
            </Stack>
          </CardOverflow>
          <CardOverflow
            sx={{ height: 200, maxHeight: 200, overflow: "hidden auto" }}
          >
            <List size="sm" sx={{ "--List-padding": 0 }}>
              {items.map((value: string | number) => {
                const label = options.find(({ id }) => id === value)?.label
                return (
                  <ListItem key={value} onClick={handleToggle(value)}>
                    <Checkbox
                      overlay
                      size="sm"
                      checked={checked.includes(value)}
                      tabIndex={-1}
                      label={label}
                    />
                  </ListItem>
                )
              })}
            </List>
          </CardOverflow>
        </>
      )}
    </Card>
  )

  useEffect(() => {
    setValue(name, selected)
  }, [setValue, name, selected])

  return (
    <Controller
      name={name}
      control={control}
      render={() => {
        return (
          <Stack
            sx={{
              flexDirection: "row",
              gap: 2,
              alignItems: "stretch",
              justifyContent: "space-between",
              ...sx
            }}
            {...props}
          >
            {renderList(t("label.options"), _options, "options")}
            <Stack sx={{ justifyContent: "center", gap: 1, flexShrink: 0 }}>
              <IconButton
                aria-label={t("action.selectAll")}
                variant="outlined"
                size="sm"
                onClick={handleSelectAll}
                disabled={disabled || _options.length === 0}
                sx={{ backgroundColor: _options.length && "neutral.softBg" }}
              >
                <FastArrowRight />
              </IconButton>
              <IconButton
                aria-label={t("action.select")}
                variant="outlined"
                size="sm"
                onClick={handleSelect}
                disabled={disabled || optionsChecked.length === 0}
                sx={{
                  backgroundColor: optionsChecked.length && "neutral.softBg"
                }}
              >
                <NavArrowRight />
              </IconButton>
              <IconButton
                aria-label={t("action.unselect")}
                variant="outlined"
                size="sm"
                onClick={handleUnselect}
                disabled={disabled || selectedChecked.length === 0}
                sx={{
                  backgroundColor: selectedChecked.length && "neutral.softBg"
                }}
              >
                <NavArrowLeft />
              </IconButton>
              <IconButton
                aria-label={t("action.unselectAll")}
                variant="outlined"
                size="sm"
                onClick={handleUnselectAll}
                disabled={disabled || selected.length === 0}
                sx={{ backgroundColor: selected.length && "neutral.softBg" }}
              >
                <FastArrowLeft />
              </IconButton>
            </Stack>
            {renderList(t("state.selected"), selected, "selected")}
          </Stack>
        )
      }}
    />
  )
}
