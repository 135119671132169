import {
  GameServerBuild,
  GameServerBuildDeploymentType,
  DataState,
  GameServerBuildType
} from "@liveops-portal/lib"
import { Stack, Typography } from "@mui/joy"
import React, { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Filters } from "@/components/filters/filters"
import { GameServerBuildCard } from "@/components/game-server-build-card/game-server-build-card"
import { Spinner } from "@/components/spinner/spinner"
import { useAppSelector } from "@/hooks/store"
import { useGetGameServerBuildsQuery } from "@/store/api/game-server-build"
import { selectEnv, selectGame } from "@/store/slices/context"
import { selectFilteringResults } from "@/store/slices/filtering"

/**
 * Component representing the build page.
 */
export const GameServerBuildsPage: React.FC = () => {
  const { t } = useTranslation()
  const env = useAppSelector(selectEnv)
  const gameId = useAppSelector(selectGame)
  const filteredGameServerBuilds = useAppSelector(selectFilteringResults)

  const { data: gameServerBuilds, isFetching } = useGetGameServerBuildsQuery(
    { gameId, env },
    { skip: !gameId || !env }
  )

  // TODO: Memo shouldn't be used here 🙃
  const searchable = useMemo(
    () => ["buildName", "buildId", "codeChangeList", "dataChangeList"],
    []
  )

  const filters = [
    {
      field: "buildType" as const,
      label: t("label.gameServerBuild.buildType"),
      options: GameServerBuildType
    },
    {
      field: "deploymentType" as const,
      label: t("label.gameServerBuild.deploymentType"),
      options: GameServerBuildDeploymentType
    },
    {
      field: "state" as const,
      label: t("label.gameServerBuild.state"),
      options: DataState
    }
  ]

  return (
    <>
      <Spinner loading={isFetching} />

      {gameServerBuilds?.length ? (
        <Stack sx={{ gap: 2 }}>
          <Filters
            items={gameServerBuilds}
            searchable={searchable}
            filters={filters}
          />
          {filteredGameServerBuilds.length ? (
            <Stack sx={{ gap: 2 }}>
              {filteredGameServerBuilds.map((build: GameServerBuild) => (
                <GameServerBuildCard key={build.buildId} {...build} />
              ))}
            </Stack>
          ) : (
            <Typography>
              {t("message.noItems.selectedCriteria", {
                item: "item.builds"
              })}
            </Typography>
          )}
        </Stack>
      ) : (
        <Typography>
          {t("message.noItems.parent", {
            item: "item.builds",
            parent: "item.game"
          })}
        </Typography>
      )}
    </>
  )
}
