import {
  ApiError,
  ByEnvQuery,
  sortByKey,
  Space,
  SpaceCreateRequest,
  SpaceCreateResponse,
  SpaceValidation
} from "@liveops-portal/lib"
import notify from "@/helpers/notify"
import i18next from "@/i18n"
import { api } from "@/store/api"

export const spaceApi = api
  .enhanceEndpoints({
    addTagTypes: ["Space"]
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      findAllSpaces: builder.query<Space[], ByEnvQuery>({
        query: () => ({
          url: "spaces"
        }),
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ spaceId }) => ({
                  type: "Space" as const,
                  id: spaceId
                })),

                { type: "Space", id: "ALL" }
              ]
            : [{ type: "Space", id: "ALL" }],
        transformResponse: (spaces: Space[]) => sortByKey(spaces, "spaceName"),
        onQueryStarted(_, { queryFulfilled }) {
          queryFulfilled.catch(({ error }) => {
            notify.error({
              message: i18next.t("api.findAllSpaces.error"),
              error: error as ApiError
            })
          })
        }
      }),
      createSpace: builder.mutation<SpaceCreateResponse, SpaceCreateRequest>({
        query: ({ gameId, spaceName, spacePrefix, qdOperationReason }) => ({
          url: "spaces",
          method: "POST",
          params: { gameId },
          body: {
            spaceName,
            spacePrefix
          },
          headers: {
            "QD-Operation-Reason": qdOperationReason
          }
        }),
        invalidatesTags: [{ type: "Space", id: "ALL" }],
        onQueryStarted(_, { queryFulfilled }) {
          queryFulfilled
            .then(() => {
              notify.success(i18next.t("api.createSpace.success"))
            })
            .catch(({ error }) => {
              notify.error({
                message: i18next.t("api.createSpace.error"),
                error: error as ApiError
              })
            })
        }
      }),
      validateSpace: builder.query<SpaceValidation, string>({
        query: (spaceId) => ({
          url: `spaces/${spaceId}/validate`
        }),
        onQueryStarted(_, { queryFulfilled }) {
          queryFulfilled.catch(({ error }) => {
            notify.error({
              message: i18next.t("api.validateSpace.error"),
              error: error as ApiError
            })
          })
        }
      }),
      fixSpace: builder.query<SpaceValidation, string>({
        query: (spaceId) => ({
          url: `spaces/${spaceId}/fix`,
          method: "PATCH"
        }),
        onQueryStarted(_, { queryFulfilled }) {
          queryFulfilled.catch(({ error }) => {
            notify.error({
              message: i18next.t("api.fixSpace.error"),
              error: error as ApiError
            })
          })
        }
      })
    }),
    overrideExisting: false
  })

export const {
  useFindAllSpacesQuery,
  useCreateSpaceMutation,
  useLazyValidateSpaceQuery,
  useLazyFixSpaceQuery
} = spaceApi
