import { FeatureSwitch, FeatureSwitchSetRequest } from "@liveops-portal/lib"
import { IconButton } from "@mui/joy"
import { EditPencil } from "iconoir-react"
import { useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { Form } from "@/components/form/form"
import { Modal } from "@/components/modal/modal"
import { TransferList } from "@/components/transfer-list/transfer-list"
import { useAppSelector } from "@/hooks/store"
import { useSetSwitchMutation } from "@/store/api/feature-switch"
import { selectSpace } from "@/store/slices/context"

interface Props {
  name: FeatureSwitch["switchName"]
  options: { label: string; id: string | number }[]
  preSelected?: (string | number)[]
  disabled?: boolean
}

type FormData = FeatureSwitchSetRequest

export const FeatureSwitchUpdate = ({
  name,
  options,
  preSelected,
  disabled = false
}: Props) => {
  const { t } = useTranslation()
  const spaceId = useAppSelector(selectSpace)
  const methods = useForm<FormData>({
    mode: "all"
  })
  const { reset: resetForm } = methods
  const [setSwitch, { isSuccess, isLoading }] = useSetSwitchMutation()
  const [open, setOpen] = useState<boolean>(false)

  const onSubmitHandler = (data: FormData) => {
    setSwitch({
      ...data,
      switchName: name,
      spaceId
    })
  }

  const onCloseHandler = useCallback(() => {
    resetForm()
    setOpen(false)
  }, [resetForm])

  useEffect(() => {
    if (isSuccess) {
      onCloseHandler()
    }
  }, [isSuccess, onCloseHandler])

  return (
    <>
      <IconButton
        disabled={disabled}
        variant="solid"
        color="primary"
        onClick={() => setOpen(true)}
        aria-label={t("action.update")}
      >
        <EditPencil />
      </IconButton>

      <Modal
        open={open}
        onClose={onCloseHandler}
        title={name}
        sx={{
          "--ModalDialog-minWidth": "600px"
        }}
      >
        <Form
          auditable
          methods={methods}
          loading={isLoading}
          submitLabel="Set"
          onSubmit={onSubmitHandler}
        >
          <TransferList
            name="switchValue"
            options={options}
            preSelected={preSelected}
          />
        </Form>
      </Modal>
    </>
  )
}
